import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import HeadsetIcon from '@material-ui/icons/Headset'
import InfoIcon from '@material-ui/icons/Info'
import TodayIcon from '@material-ui/icons/Today'
import { Alert } from '@material-ui/lab'
import React from 'react'

import { SearchI } from '../../../../interfaces/service'
import useStyles from './styles'

interface SchedulerTypeActionProps {
  serviceSelected: SearchI
  handleSetServiceType: (value: 'online' | 'presencial' | null) => void
  handleSetTypeAttendence: (value: 'Online') => void
  handleNexStep: (value: 'next' | 'prev') => void
}

export default function ScheduleTypeAction({
  serviceSelected,
  handleSetServiceType,
  handleSetTypeAttendence,
  handleNexStep,
}: SchedulerTypeActionProps): JSX.Element {
  const classes = useStyles()
  const pathEnv = process.env
  return (
    <Box className={classes.contentTypeScheduler}>
      <Typography className={classes.textInfoScheduler}>
        Como irá ocorrer o atendimento?
      </Typography>
      <Box className={classes.schedules}>
        <Box
          id="online-att"
          onClick={() => {
            if (pathEnv.REACT_APP_AGENDAMENTO_ONLINE_ATIVO !== 'true') return
            if (!serviceSelected?.online) return
            handleSetServiceType('online')
            handleSetTypeAttendence('Online')
            handleNexStep('next')
          }}
          className={
            (serviceSelected?.online &&
              pathEnv.REACT_APP_AGENDAMENTO_ONLINE_ATIVO) === 'true'
              ? classes.boxItem
              : classes.boxItemDisabled
          }
        >
          <HeadsetIcon className={classes.iconItem} />
          <Typography className={classes.textItem}>
            Atendimento Online
          </Typography>
        </Box>
        <Box
          id="presencial-att"
          onClick={() => {
            if (pathEnv.REACT_APP_AGENDAMENTO_PRESENCIAL_ATIVO !== 'true')
              return
            if (!serviceSelected?.agendavel) return
            handleSetServiceType('presencial')
            handleNexStep('next')
          }}
          className={
            serviceSelected?.agendavel &&
            pathEnv.REACT_APP_AGENDAMENTO_PRESENCIAL_ATIVO === 'true'
              ? classes.boxItem
              : classes.boxItemDisabled
          }
        >
          <TodayIcon className={classes.iconItem} />
          <Typography className={classes.textItem}>
            Atendimento Presencial
          </Typography>
        </Box>
      </Box>
      <Alert
        icon={<InfoIcon className={classes.warnIcon} />}
        severity="warning"
      >
        <strong>Orientações: </strong>
        Mantenha seus dados atualizados para não deixar de receber alertas de
        seu agendamento.
      </Alert>
    </Box>
  )
}
