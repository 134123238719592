import { makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useContext } from 'react'

import ColorContext from '../../Context/colorContext'

export default function useHeaderStyles() {
  const { colors } = useContext(ColorContext)
  const matches = useMediaQuery('(min-width:1250px)')
  const laptop = useMediaQuery('(max-width:1370px)')
  const matchesMobile = useMediaQuery('(min-width:660px)')
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px 10px',
      minHeight: 60,
      background: colors.accentColorBackground,
      [theme.breakpoints.down(1250)]: {
        height: 'auto',
      },
    },
    text: {
      textTransform: 'capitalize',
      fontFamily: 'Roboto',
      margin: '0px 15px',
      color: colors.textBody,
      cursor: 'pointer',
    },
    textButton: {
      fontWeight: 500,
      fontStyle: 'normal',
      marginLeft: 3,
      fontSize: 16,
      color: colors.textAccentColor,
      textTransform: 'uppercase',
    },
    textName: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      textTransform: 'capitalize',
      color: colors.textAccentColor,
    },
    textNamePop: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      textTransform: 'capitalize',
      color: colors.accentColor,
    },
    avatar: {
      height: 40,
      width: 40,
      backgroundColor: '#737B7D',
      border: `1px solid ${colors.accentColor}`,
    },
    menuItem: {
      padding: '18px 37px 18px 20px',
      display: 'flex',
      alignItems: 'center',
      color: '#737B7D',
      minWidth: 134,
      backgroundColor: colors.colorBackgroundSecundary,
      '&:hover': {
        background: colors.colorBackground,
      },
      [theme.breakpoints.down('xs')]: {
        padding: '10px 16px',
      },
    },
    textMenuItem: {
      fontSize: theme.typography.pxToRem(16),
      marginLeft: 15,
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: colors.textBody,
    },
    textCpf: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 400,
      textAlign: 'start',
      fontFamily: 'Roboto',
      color: colors.textAccentColor,
    },
    textCpfPop: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 400,
      textAlign: 'start',
      fontFamily: 'Roboto',
      color: colors.accentColor,
    },
    buttonAccessibility: {
      display: 'inline-block',
      padding: 6,
      minWidth: 0,
      minHeight: 0,
      fontSize: 14,
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.accentColor,
    },
    boxAccessibility: {
      display: 'flex',
      alignItems: 'center',
    },
    boxSubMenu: {
      display: 'flex',
      alignItems: 'center',
      gap: 40,
    },
    boxSub: {
      width: '1050px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
      alignItems: 'center',
    },
    boxSocialMedia: {
      display: 'flex',
      alignItems: 'center',
      gap: 9,
    },
    boxSocialMediaItems: {
      display: 'flex',
      alignItems: 'center',
      // gap: 8,
    },
    titleBoxSocialMedia: {
      fontSize: theme.typography.pxToRem(14),
      color: '#fff',
      fontWeight: 400,
      whiteSpace: 'nowrap',
      flexShrink: 0,
    },
    linksBoxSubMenu: {
      fontSize: theme.typography.pxToRem(13),
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: '#fff',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: 3,
    },
    textLocale: {
      display: 'flex',
      alignItems: 'center',
      padding: '3px 15px',
      fontSize: 12,
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: '#3E3E3E',
      borderLeft: '1px solid #DBDFE2',
      borderRight: '1px solid #DBDFE2',
    },
    textFontContrast: {
      fontSize: 14,
      fontWeight: 500,
      color: colors.textBody,
    },
    boxInfo: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: colors.accentColor,
    },
    btnSocial: {
      color: theme.palette.primary.main,
      padding: 0,
      width: 16,
      height: 16,
    },
    btnFacebook: {
      color: colors.textAccentColor,
      padding: 0,
      width: 16,
      height: 16,
    },
    boxIcon: {
      width: 32,
      height: 32,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.accentColor,
      '&:hover': {
        backgroundColor: colors.accentColor,
        opacity: 0.8,
      },
    },
    iconSocial: {
      width: 12,
      height: 12,
      color: '#fff',
    },
    img: {
      width: '100%',
      maxWidth: 126,
      height: 'auto',
    },

    backdropMenu: {
      width: 300,
      height: '100vh',
      background: colors.accentColorBackground,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100vw - var(--scrollbar-width, 0px))',
      },
    },
    menuHeader: {
      padding: '24px 20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    menuHeaderTitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      textTransform: 'uppercase',
      color: colors.textAccentColor,
    },
    itemMenu: {
      padding: '15px 20px',
    },
    divider: {
      color: '#fff',
      margin: '0px 8px',
    },
    menuPrimary: {
      // width: '100%',
      // height: 32,
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
    },
    contentMenuPrimary: {
      gap: '1rem',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    iconMenuWorkspace: {
      width: 24,
      height: 24,
      color: colors.accentColor,
    },
    userPanel: {
      padding: '11px 22px',
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(15),
      color: colors.accentColor,
      backgroundColor: colors.colorBackground,
      borderRadius: 24,
      fontWeight: 500,

      '&:hover': {
        backgroundColor: colors.colorBackground,
      },
    },
    drawerSocialNetworksButton: {
      color: '#fff',
      display: 'none',
      '@media (max-width: 1370px)': {
        display: 'flex',
      },
    },
  }))
  const classes = useStyles()

  return {
    colors,
    classes,
    matches,
    matchesMobile,
    laptop,
  }
}
