/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext } from 'react'

const refContext = createContext({
  refCategory: null,
  refApps: null,
  refService: null,
  refEstatistic: null,
  refAttendance: null,
  refPersona: null,
  refNoticias: null,
  refAgendaCultural: null,
  refInicio: null,
  refVideos: null,
  refRegistryType: null,
  scrollToRef: (_ref: React.MutableRefObject<any>) => {},
  perfilUser: null,
})

export default refContext
