/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import React from 'react'
import { TbMail } from 'react-icons/tb'
import { Link as LinkRouter } from 'react-router-dom'

import { useFooterStyles } from './styles'

export default function Footer(): JSX.Element {
  const { classes, isLargeScreen, isSmallScreen } = useFooterStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.footer}>
        <Box
          width="100%"
          maxWidth="1320px"
          margin={isLargeScreen ? '40px 0' : '20px 0'}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            style={{
              gap: isSmallScreen ? 46 : 0,
            }}
          >
            <LinkRouter to="/">
              <img
                alt="Logo Salvador"
                style={{
                  width: isSmallScreen ? 230 : 300,
                  height: isSmallScreen ? 70 : 91,
                }}
                src={process.env.REACT_APP_LOGO_FOOTER}
                loading="lazy"
              />
            </LinkRouter>
            <Box className={classes.itemFooter}>
              <Typography className={classes.titleItemFooter}>
                Palácio Thomé de Souza
              </Typography>
              <Typography className={classes.textItemFooter}>
                Praça Municipal, s/n <br />
                Centro
                <br />
                <a
                  href={process.env.REACT_APP_LOCALIZACAO}
                  target="_blank"
                  className={classes.location}
                  rel="noopener noreferrer"
                >
                  Salvador - BA -
                  <LocationOnIcon fontSize="inherit" />
                  mapa
                </a>
              </Typography>
              <Typography className={classes.textItemFooter}>
                <a
                  className={classes.mail}
                  href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}
                >
                  <TbMail fontSize="inherit" />
                  {process.env.REACT_APP_SUPPORT_MAIL}
                </a>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
