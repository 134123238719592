/* eslint-disable no-underscore-dangle */
import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/json'

const {
  REACT_APP_API_SSO,
  REACT_APP_SSO_MTI,
  REACT_APP_API_SISECI,
  REACT_APP_API_NOTICIAS,
  REACT_APP_API_SISECI_KEY,
  REACT_APP_API_SSO_KEY,
  REACT_APP_API_NOTICIAS_KEY,
  REACT_APP_NOTICIAS_EXTERNAL,
  REACT_APP_NOTICIAS_EXTERNAL_URL,
  REACT_APP_API_FORM_FLOW,
  REACT_APP_API_OUVIDORIA,
  REACT_APP_API_OUVIDORIA_KEY,
  REACT_APP_API_156,
  REACT_APP_OSM,
} = process.env

const apisso = axios.create({
  baseURL: REACT_APP_API_SSO ?? '',
})

const ssoMti = axios.create({
  baseURL: REACT_APP_SSO_MTI ?? '',
})

const apisiseci = axios.create({
  baseURL: REACT_APP_API_SISECI ?? '',
})

const apinoticias = axios.create({
  baseURL:
    REACT_APP_NOTICIAS_EXTERNAL === 'true'
      ? REACT_APP_NOTICIAS_EXTERNAL_URL
      : REACT_APP_API_NOTICIAS,
})

const apiformflow = axios.create({
  baseURL: REACT_APP_API_FORM_FLOW,
})

const apiouvidoria = axios.create({
  baseURL: REACT_APP_API_OUVIDORIA,
})

const apiAdmin156 = axios.create({
  baseURL: REACT_APP_API_156,
})

const apiosm = axios.create({
  baseURL: REACT_APP_OSM,
})

apisiseci.defaults.headers.common.Authorization = REACT_APP_API_SISECI_KEY ?? ''
apisso.defaults.headers.common.Authorization = REACT_APP_API_SSO_KEY ?? ''
apinoticias.defaults.headers.common.Authorization =
  REACT_APP_API_NOTICIAS_KEY ?? ''
apiouvidoria.defaults.headers.common.Authorization =
  REACT_APP_API_OUVIDORIA_KEY ?? ''

export {
  apisso,
  apisiseci,
  ssoMti,
  apinoticias,
  apiformflow,
  apiouvidoria,
  apiAdmin156,
  apiosm,
}
