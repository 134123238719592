import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { ClassNameMap } from '@material-ui/styles'
import React from 'react'

import goToPage from '../../../../utils/goToPage'
import { socialNetworksItems } from './data'

type SocialNetworksProps = {
  classes: ClassNameMap
}

export default function SocialNetworks({ classes }: SocialNetworksProps) {
  return (
    <Box className={classes.boxSocialMedia}>
      <Typography className={classes.titleBoxSocialMedia}>Siga-nos:</Typography>
      <Box className={classes.boxSocialMediaItems}>
        {socialNetworksItems.map((socialNetworkItem, index) => (
          <>
            <IconButton
              key={`${socialNetworkItem.icon}-key-${index}`}
              onClick={() => {
                goToPage(socialNetworkItem.link)
              }}
              className={classes.btnSocial}
            >
              <socialNetworkItem.icon className={classes.iconSocial} />
            </IconButton>
            {index < socialNetworksItems.length - 1 && (
              <Typography className={classes.divider}>|</Typography>
            )}
          </>
        ))}
      </Box>
    </Box>
  )
}
