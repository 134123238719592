/* eslint-disable @typescript-eslint/naming-convention */
import { faBriefcase, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import Theme from '@material-ui/core/styles/MuiThemeProvider'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import React, { FormEvent, Fragment, useState } from 'react'

import { buscarCNPJ } from '../../services/agendamento'
import cnpjMask, { removeCnpjMask } from '../../utils/cnpjFormat'
import AlertCard from './components/AlertCard'

interface IdentificationOptionCheckProps {
  profile: 'Cidadão' | 'Empresa'
  listOptions: Array<'Cidadão' | 'Empresa' | 'Servidor'>
  cnpjNumber: string
  companyCountry: string | null
  onCompanyCountry: (value: string) => void
  controlRadio: (value: 'Cidadão' | 'Empresa') => void
  handleChangeValueCnpj: (value: FormEvent) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    width: '100%',
    maxWidth: 536,
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 72,
    padding: '12px',
    border: '1px solid #DAE1EF',
    borderRadius: 4,
    cursor: 'pointer',
    [theme.breakpoints.down(660)]: {
      padding: '22px 22px 32px 22px',
    },
  },
  cardChecked: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  radioChecked: {
    color: theme.palette.primary.main,
  },
  radioInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  cardHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 24px',
  },
  icon: {
    width: 21,
    height: 24,
    color: '#DAE1EF',
    [theme.breakpoints.down(660)]: {
      width: 24,
      height: 24,
    },
  },
  iconChecked: {
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    display: 'block',
    [theme.breakpoints.down(660)]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  containerInfoEmployer: {
    width: '100%',
    marginTop: 27,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  containerLoading: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default function IdentificationOptionCheck({
  profile,
  listOptions,
  cnpjNumber,
  companyCountry,
  onCompanyCountry,
  controlRadio,
  handleChangeValueCnpj,
}: IdentificationOptionCheckProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const [companyAddress, setCompanyAddress] = useState<string | null>(null)
  const [companyName, setCompanyName] = useState<string | null>(null)

  const handleResetValues = () => {
    setCompanyAddress(null)
    onCompanyCountry(null)
    setCompanyName(null)
  }

  const getDataCompany = async () => {
    if (!cnpjNumber) return
    try {
      setLoading(true)
      const cnpjWithoutMask = removeCnpjMask(cnpjNumber)
      const { data } = await buscarCNPJ(cnpjWithoutMask)

      if (data?.dados_sefaz) {
        const { dados_sefaz } = data
        setCompanyName(dados_sefaz.NomeEmpresarial)
        setCompanyAddress(
          `${dados_sefaz.Logradouro}, ${dados_sefaz.NumeroLogradouro} - ${dados_sefaz.CEP}`,
        )
        onCompanyCountry(dados_sefaz.NomeMunicipio)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const isAlertVisible =
    profile === 'Empresa' &&
    typeof companyCountry === 'string' &&
    companyCountry !== 'SALVADOR'

  const classes = useStyles()

  return (
    <>
      {listOptions.length &&
        listOptions.map((list) => {
          if (list === 'Cidadão') {
            return (
              <Box key={list} id="citizen-profile" className={classes.item}>
                <Box
                  className={`${
                    profile === 'Cidadão'
                      ? `${classes.card} ${classes.cardChecked}`
                      : classes.card
                  }`}
                  onClick={() => {
                    handleResetValues()
                    controlRadio('Cidadão')
                  }}
                >
                  <Box className={classes.cardHeader}>
                    <Box className={classes.radioInfo}>
                      <FontAwesomeIcon
                        className={`${
                          profile === 'Cidadão'
                            ? `${classes.icon} ${classes.iconChecked}`
                            : classes.icon
                        }`}
                        icon={faUserCheck}
                      />
                      <Box>
                        <Typography className={classes.title}>
                          Cidadão
                        </Typography>
                      </Box>
                    </Box>
                    <Radio
                      color="default"
                      checked={profile === 'Cidadão'}
                      onChange={() => {
                        handleResetValues()
                        controlRadio('Cidadão')
                      }}
                      value={profile}
                      name="radio-button"
                      classes={{ checked: classes.radioChecked }}
                    />
                  </Box>
                </Box>
              </Box>
            )
          }
          if (list === 'Empresa') {
            return (
              <Box key={list} id="company-profile" className={classes.item}>
                <Box
                  className={`${
                    profile === 'Empresa'
                      ? `${classes.card} ${classes.cardChecked}`
                      : classes.card
                  }`}
                  onClick={() => {
                    controlRadio('Empresa')
                  }}
                >
                  <Box className={classes.cardHeader}>
                    <Box className={classes.radioInfo}>
                      <FontAwesomeIcon
                        className={`${
                          profile === 'Empresa'
                            ? `${classes.icon} ${classes.iconChecked}`
                            : classes.icon
                        }`}
                        icon={faBriefcase}
                      />
                      <Box>
                        <Typography className={classes.title}>
                          Perfil Empresa
                        </Typography>
                      </Box>
                    </Box>
                    <Radio
                      color="default"
                      checked={profile === 'Empresa'}
                      onChange={() => controlRadio('Empresa')}
                      value="Empresa"
                      name="radio-button"
                      classes={{ checked: classes.radioChecked }}
                    />
                  </Box>
                  {profile === 'Empresa' && (
                    <Box className={classes.containerInfoEmployer}>
                      <FormControl fullWidth>
                        <TextField
                          id="cnpj-input"
                          label="Informe o CNPJ"
                          fullWidth
                          required
                          variant="outlined"
                          value={cnpjMask(cnpjNumber) || ''}
                          onChange={(value) => {
                            handleChangeValueCnpj(value)
                            handleResetValues()
                          }}
                          onBlur={getDataCompany}
                        />
                      </FormControl>
                      {loading ? (
                        <div className={classes.containerLoading}>
                          <CircularProgress size={20} />
                        </div>
                      ) : (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              id="social-input"
                              label="Razão Social"
                              fullWidth
                              required
                              variant="outlined"
                              disabled
                              value={companyName || ''}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              id="cep-input"
                              label="Endereço com CEP"
                              fullWidth
                              required
                              disabled
                              value={companyAddress || ''}
                              variant="outlined"
                            />
                          </FormControl>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            )
          }
          return ''
        })}
      <AlertCard isVisible={isAlertVisible} />
    </>
  )
}
