import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'
import React, { ReactNode } from 'react'

interface IdentificationOptionsCheckProps {
  children: ReactNode
}

const useStyles = makeStyles(() => ({
  containerCards: {
    display: 'flex',
    width: '100%',
    margin: '24px 0px',
    gap: 16,
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export default function IdentificationOptionsCheck({
  children,
}: IdentificationOptionsCheckProps): JSX.Element {
  const classes = useStyles()
  return <Box className={classes.containerCards}>{children}</Box>
}
