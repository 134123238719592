/* eslint-disable react-hooks/exhaustive-deps */
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import BackupIcon from '@material-ui/icons/Backup'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import RemoveIcon from '@material-ui/icons/Remove'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone'
import React, { FormEvent, useContext, useState } from 'react'

import colorContext from '../../../../Context/colorContext'
import { formatBytes } from '../../../../utils/formatBytes'
import { DemandData } from '../..'

export interface SubjectProps {
  id: number
  titulo: string
  tipo_manifestacao: number
}

interface DescriptionProps {
  controlStep: (action: 'prev' | 'next') => void
  changeDemandData: (data: DemandData) => void
  demandData: DemandData
  descriptionDefault: string
}

export default function Description({
  controlStep,
  changeDemandData,
  demandData,
  descriptionDefault,
}: DescriptionProps): JSX.Element {
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles((theme: Theme) => ({
    boxStepContent: {
      padding: '0px 10px',
      marginTop: 48,
    },
    actionTitle: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 500,
      textAlign: 'center',
      color: '#1F2937',
      [theme.breakpoints.down(660)]: {
        padding: '0px 10px',
        fontSize: theme.typography.pxToRem(18),
      },
    },
    grid: {
      marginTop: 41,
      marginBottom: 56,
      [theme.breakpoints.down(660)]: {
        marginTop: 32,
        marginBottom: 50,
      },
    },
    boxActions: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 24,
      paddingBottom: '56px',
      [theme.breakpoints.down(660)]: {
        flexDirection: 'column',
        gap: 30,
      },
    },
    warning: {
      fontSize: theme.typography.pxToRem(14),
      color: '#4F5766',
      fontWeight: 400,
    },
    boxButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      [theme.breakpoints.down(660)]: {
        width: '100%',
      },
    },
    button: {
      padding: '12px 43px',
      border: 'none',
      borderRadius: 4,
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(16),
      cursor: 'pointer',
      fontWeight: 500,
      fontFamily: 'Roboto',
      [theme.breakpoints.down(660)]: {
        width: '100%',
        padding: '12px 0px',
      },
    },
    forwardButton: {
      color: '#fff',
      backgroundColor: colors.accentColorBackground,
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 11,
      padding: ' 8px, 22px, 8px, 16px',
      border: 'none',
      backgroundColor: colors.colorBackground,
      color: colors.textBody,
      [theme.breakpoints.down(660)]: {
        justifyContent: 'center',
      },
    },
    icon: {
      width: 18,
      height: 18,
      color: '#1F2937',
    },
    boxDropzone: {
      marginBottom: 39,
      [theme.breakpoints.down(660)]: {
        marginBottom: 24,
      },
    },
    dropzone: {
      minHeight: 58,
      backgroundColor: '#F6F8FC',
      marginBottom: 30,
    },
    dropzoneIcon: {
      display: 'none',
    },
    dropzoneText: {
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    document: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #E1E6EF',
      padding: 0,
      marginBottom: 8,
    },
    documentName: {
      fontSize: theme.typography.pxToRem(16),
      fontStyle: 'normal',
      fontWeight: 400,
      paddingLeft: 20,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(12),
        fontStyle: 'bold',
      },
    },
    documentIcon: {
      width: 30,
      height: 30,
      marginRight: 10,
    },
    removeDoc: {
      border: 'none',
      backgroundColor: 'transparent',
      borderLeft: '1px solid #E1E6EF',
      width: 50,
      cursor: 'pointer',
    },
    removeIcon: {
      width: 22,
      height: 22,
      color: colors.buttonErrorColor,
    },
    alert: {
      marginTop: 48,
      backgroundColor: 'rgba(246, 195, 63, 0.2)',
      width: '100%',
      minHeight: 48,
      height: 'auto',
      borderRadius: 4,
      padding: '6px 16px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    iconInfo: {
      color: 'rgba(246, 195, 63, 1)',
    },
  }))
  const classes = useStyles()
  const [description, setDescription] = useState('')
  const [files, setFiles] = useState<FileObject[]>([])

  const removeFile = (file: FileObject) => {
    const fileFilter = files.filter(
      (fileFiltering) => fileFiltering.data !== file.data,
    )
    setFiles(fileFilter)
  }

  const addFile = (file: FileObject[]) => {
    if (file.length > 1) {
      setFiles(file)
    } else {
      setFiles([file[0], ...files])
    }
  }

  const createDescriptionData = (event: FormEvent) => {
    event.preventDefault()
    changeDemandData({
      conteudo: description,
      canal_entrada: 'Internet',
      arquivos: files,
      ...demandData,
    })
    controlStep('next')
  }

  return (
    <Box className={classes.boxStepContent}>
      <Typography className={classes.actionTitle}>
        Descreva abaixo o conteúdo de sua demanda
      </Typography>
      <Alert
        icon={<InfoOutlinedIcon className={classes.iconInfo} />}
        className={classes.alert}
      >
        Sugestão para melhorar a descrição:{' '}
        <strong>{descriptionDefault || ''}</strong>
      </Alert>
      <form onSubmit={createDescriptionData}>
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xs={12}>
            <TextField
              id="outlined-full-width"
              label="Registre aqui"
              multiline
              required
              placeholder="Descrição da manifestação"
              fullWidth
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              variant="outlined"
              minRows={5}
              inputProps={{
                style: {
                  color: '#1F2937',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.boxDropzone}>
              <DropzoneAreaBase
                onAdd={addFile}
                fileObjects={files}
                acceptedFiles={['image/jpeg', 'image/png', '.pdf']}
                showPreviewsInDropzone={false}
                dropzoneText="Anexar arquivo"
                showFileNames={false}
                showFileNamesInPreview={false}
                showAlerts={['error']}
                filesLimit={30}
                maxFileSize={31457280}
                classes={{
                  root: classes.dropzone,
                  icon: classes.dropzoneIcon,
                }}
                dropzoneParagraphClass={classes.dropzoneText}
              />
              {files &&
                files.map((file) => (
                  <Box
                    className={classes.document}
                    key={`key-${file.file.name}`}
                  >
                    <p className={classes.documentName}>
                      <BackupIcon className={classes.documentIcon} />
                      {`${file?.file?.name} (${formatBytes(file.file?.size)})`}
                    </p>
                    <button
                      aria-label="remover"
                      type="button"
                      onClick={(): void => removeFile(file)}
                      className={classes.removeDoc}
                    >
                      <RemoveIcon className={classes.removeIcon} />
                    </button>
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.boxActions}>
          <span className={classes.warning}>
            * Campo de preenchimento obrigatorio
          </span>
          <Box className={classes.boxButton}>
            <button
              type="button"
              onClick={() => controlStep('prev')}
              className={`${classes.button} ${classes.backButton}`}
            >
              <ArrowBackIcon className={classes.icon} />
              Voltar
            </button>
            <button
              type="submit"
              className={`${classes.button} ${classes.forwardButton}`}
              style={{
                opacity: description.length === 0 ? '0.7' : '1',
              }}
            >
              Avançar
            </button>
          </Box>
        </Box>
      </form>
    </Box>
  )
}
