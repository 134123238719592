/* eslint-disable react-hooks/exhaustive-deps */
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Theme from '@material-ui/core/styles/MuiThemeProvider'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/styles'
import React, { FormEvent, useContext, useEffect, useState } from 'react'

import colorContext from '../../../../Context/colorContext'
import { getGeolocationByCEP } from '../../../../services/geoLocation'
import { searchCep } from '../../../../services/ouvidoria'
import { cepMask } from '../../../../utils/cepFormat'
import MapComponent from '../../../NewManifestation/Steps/Localization/components/MapComponent'
import { DemandData } from '../..'
interface LocalizationProps {
  controlStep: (action: 'prev' | 'next') => void
  changeDemandData: (data: DemandData) => void
  demandData: DemandData
}

export default function Localization({
  controlStep,
  changeDemandData,
  demandData,
}: LocalizationProps): JSX.Element {
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles((theme: Theme) => ({
    boxStepContent: {
      padding: '0px 10px',
      marginTop: 48,
    },
    actionTitle: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 500,
      textAlign: 'center',
      color: colors.textBody,
      marginBottom: 48,
      [theme.breakpoints.down(660)]: {
        padding: '0px 10px',
        fontSize: theme.typography.pxToRem(18),
        marginBottom: 32,
      },
    },
    warning: {
      display: 'block',
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    form: {
      width: '100%',
    },
    menuItem: {
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBody,
      fontWeight: 500,
    },
    buttonAdd: {
      width: 56,
      height: 56,
      margin: '0 auto',
      border: '1px solid #DAE1EF',
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      fontFamily: 'Roboto',
      [theme.breakpoints.down(660)]: {
        width: '100%',
        height: 'auto',
        padding: '16px 0px',
        gap: 13,
        fontSize: theme.typography.pxToRem(15),
        color: colors.textBody,
        fontWeight: 500,
        textTransform: 'uppercase',
      },
    },
    iconAdd: {
      width: 24,
      height: 24,
      color: colors.textBody,
    },
    boxActions: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 24,
      paddingBottom: '56px',
      [theme.breakpoints.down(660)]: {
        flexDirection: 'column',
        gap: 30,
      },
    },
    warningBottom: {
      fontSize: theme.typography.pxToRem(14),
      color: '#4F5766',
      fontWeight: 400,
    },
    boxButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      [theme.breakpoints.down(660)]: {
        width: '100%',
      },
    },
    button: {
      padding: '12px 43px',
      border: 'none',
      borderRadius: 4,
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(16),
      cursor: 'pointer',
      fontWeight: 500,
      fontFamily: 'Roboto',
      [theme.breakpoints.down(660)]: {
        width: '100%',
        padding: '12px 0px',
      },
    },
    forwardButton: {
      color: colors.textAccentColor,
      backgroundColor: colors.accentColorBackground,
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 11,
      padding: ' 8px, 22px, 8px, 16px',
      border: 'none',
      backgroundColor: colors.colorBackground,
      color: colors.textBody,
      [theme.breakpoints.down(660)]: {
        justifyContent: 'center',
      },
    },
    icon: {
      width: 18,
      height: 18,
      color: colors.textBody,
    },
    borderError: {
      border: `1px solid ${colors.buttonErrorColor}`,
    },
    boxMap: {
      display: 'flex',
      alignItens: 'center',
      justifyContent: 'center',
      margin: '15px 10px',
    },
  }))

  const classes = useStyles()
  const [city, setCity] = useState('Salvador')
  const [loadingCep, setLoadingCep] = useState<boolean>(false)
  const [codeCep, setCodeCep] = useState<string | null>()
  const [stateAddress, setStateAddress] = useState<string>()
  const [district, setDistrict] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [addressNumber, setAddressNumber] = useState<string>()
  const [complementAddress, setComplementAddress] = useState<string>()
  const [pointReference, setPointReference] = useState<string>()
  const [errorCep, setErrorCep] = useState<string | null>(null)
  const [precision, setPrecision] = useState<boolean>(false)
  const [geolocation, setGeolocation] = useState({
    latitude: null,
    longitude: null,
  })

  const createLocalizationData = (event: FormEvent) => {
    event.preventDefault()
    changeDemandData({
      bairro: district,
      cep: codeCep,
      logradouro: address,
      cidade: city,
      estado: stateAddress,
      complemento: complementAddress,
      numero: addressNumber,
      ponto_referencia: pointReference,
      latitude: geolocation.latitude,
      longitude: geolocation.longitude,
      lat_long_precisa: precision,
      ...demandData,
    })
    controlStep('next')
  }

  const handleSearchAddress = async (searchGeolocation?: boolean) => {
    if (!codeCep) return
    try {
      setLoadingCep(true)
      const { data } = await searchCep(codeCep, 'Salvador')
      if (data && searchGeolocation) {
        getGeolocation(data.cep)
        setDistrict(data.bairro)
        setAddress(data.logradouro)
        setCity(data.cidade)
        setStateAddress(data.uf)
      }
      setErrorCep(null)
    } catch (error) {
      if (error?.response?.data) {
        setErrorCep(error?.response?.data?.message)
      }
      console.error(error)
    } finally {
      setLoadingCep(false)
    }
  }
  const getGeolocation = async (cep) => {
    try {
      const { data } = await getGeolocationByCEP(cep)
      if (data) {
        setGeolocation({ latitude: data[0].lat, longitude: data[0].lon })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const SearchButton = () => (
    <IconButton onClick={() => handleSearchAddress(true)}>
      <SearchIcon />
    </IconButton>
  )

  useEffect(() => {
    const keyDownHandle = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        handleSearchAddress(true)
      }
    }

    document.addEventListener('keydown', keyDownHandle)

    return () => {
      document.removeEventListener('keydown', keyDownHandle)
    }
  }, [handleSearchAddress])

  return (
    <Box className={classes.boxStepContent}>
      <form onSubmit={createLocalizationData}>
        <Box width="100%" marginTop="48px" marginBottom="40px">
          <Typography className={classes.actionTitle}>
            Informe o local em que ocorreu a situação relatada
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  id="outlined-full-width"
                  label="CEP"
                  required
                  fullWidth
                  value={codeCep}
                  onChange={(event) => {
                    setCodeCep(cepMask(event.target.value))
                    setErrorCep(null)
                  }}
                  variant="outlined"
                  InputProps={{
                    endAdornment: <SearchButton />,
                    style: {
                      color: '#1F2937',
                    },
                  }}
                  error={errorCep !== null}
                  InputLabelProps={{
                    shrink: Boolean(codeCep),
                  }}
                  helperText={errorCep || ''}
                  onBlur={() => handleSearchAddress(true)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  id="outlined-full-width"
                  label="Logradouro"
                  required
                  fullWidth
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: address !== '',
                  }}
                  disabled={loadingCep}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  id="outlined-full-width"
                  required
                  label="Bairro"
                  className={classes.menuItem}
                  value={district}
                  onChange={(event) => setDistrict(event.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: district !== '',
                  }}
                  disabled={loadingCep}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  id="outlined-full-width"
                  required
                  label="Número"
                  className={classes.menuItem}
                  value={addressNumber}
                  onChange={(event) => setAddressNumber(event.target.value)}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  id="outlined-full-width"
                  label="Complemento"
                  className={classes.menuItem}
                  value={complementAddress}
                  onChange={(event) => setComplementAddress(event.target.value)}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  id="outlined-full-width"
                  required
                  label="Ponto de Referência"
                  className={classes.menuItem}
                  value={pointReference}
                  onChange={(event) => setPointReference(event.target.value)}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.boxMap}>
          <MapComponent
            geolocation={geolocation}
            setCepNumber={setCodeCep}
            searchLocalization={handleSearchAddress}
            cepNumber={codeCep}
            precision={precision}
            setPrecision={setPrecision}
            setGeolocation={setGeolocation}
            setLocal={setAddress}
            setCity={setCity}
            setNeighborhood={setDistrict}
          />
        </Box>
        <Box className={classes.boxActions}>
          <span className={classes.warningBottom}>
            * Campo de preenchimento obrigatório
          </span>
          <Box className={classes.boxButton}>
            <button
              type="button"
              onClick={() => controlStep('prev')}
              className={`${classes.button} ${classes.backButton}`}
            >
              <ArrowBackIcon className={classes.icon} />
              Voltar
            </button>
            <button
              disabled={codeCep?.length !== 9}
              type="submit"
              className={`${classes.button} ${classes.forwardButton}`}
              style={{
                opacity:
                  codeCep?.length !== 9 ||
                  complementAddress?.length === 0 ||
                  district?.length === 0 ||
                  addressNumber?.length === 0 ||
                  address?.length === 0
                    ? '0.7'
                    : '1',
              }}
            >
              Avançar
            </button>
          </Box>
        </Box>
      </form>
    </Box>
  )
}
