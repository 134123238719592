import Box from '@material-ui/core/Box'
import Theme from '@material-ui/core/styles/MuiThemeProvider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

interface IdentificationHeaderProps {
  contentLogo: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  legendTitle: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700,
    color: '#1F2937',
    margin: '16px 0px',
    textAlign: 'center',
  },
  legendSubTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    color: '#1F2937CC',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  contentLogo: {
    width: '100%',
    maxHeight: 174,
    maxWidth: 261,
  },
}))

export default function IdentificationHeader({
  contentLogo = false,
}: IdentificationHeaderProps): JSX.Element {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      {contentLogo && (
        <Box className={classes.contentLogo}>
          <img
            src="/assets/images/type-scheduler.svg"
            width="100%"
            height="auto"
            alt="favorite"
          />
        </Box>
      )}
      <Typography className={classes.legendTitle}>
        Escolha o Perfil de Agendamento
      </Typography>
      <Typography className={classes.legendSubTitle}>
        Selecione abaixo seu perfil.
      </Typography>
    </Box>
  )
}
