/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from 'react'
import { StylesConfig } from 'react-select'
import ReactSelect from 'react-select/async'

import theme from '../../services/theme-service'

interface Props {
  id?: string
  name: string
  placeholder: string
  value: any
  onChange: any
  loadOptions: (value: string) => void
  noDataMessage: string
  validate: boolean
  isClearable?: boolean
  isMulti?: boolean
  styles?: StylesConfig
}

export type DataProps = {
  value: string
  label: string
}

const AsyncSelect = ({
  id,
  name,
  placeholder,
  value,
  onChange,
  loadOptions,
  noDataMessage,
  validate,
  isClearable = true,
  isMulti,
  styles,
}: Props): JSX.Element => {
  const dropdownStyles: StylesConfig<DataProps, true> = {
    container: (provided) => ({
      ...provided,
      flexGrow: 1,
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      color: isDisabled
        ? '#2e2e2e'
        : isSelected
        ? theme.palette.primary.main
        : isFocused
        ? 'black'
        : '#444',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#848484',
    }),
    control: (provided) => ({
      ...provided,
      background: '#EAEDF6',
      border: `1px solid ${validate ? theme.palette.primary.main : 'red'}`,
      padding: '10px',
      borderRadius: '4px',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#fff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#1F2937',
    }),
  }

  return (
    <ReactSelect
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      loadOptions={loadOptions}
      styles={styles || dropdownStyles}
      noOptionsMessage={() => noDataMessage}
      loadingMessage={() => 'Carregando...'}
      isClearable={isClearable}
      isMulti={isMulti}
    />
  )
}

export default AsyncSelect
