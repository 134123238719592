import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import { ClassNameMap } from '@material-ui/styles'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import getCookie from '../../../../utils/getCookies'
import { menuItems } from './data'

type MenuItemsProps = {
  setIsAuth: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
  matches: boolean
  classes: ClassNameMap
  laptop?: boolean
}

export default function MenuItems({
  setIsAuth,
  handleClose,
  classes,
  matches,
  laptop,
}: MenuItemsProps) {
  const history = useHistory()
  const token = getCookie('gov_access_token_sso')
  const tokenSiseci = getCookie('gov_access_token_sso')

  const onBeforeNavigateVerifyIsAuthenticated = useCallback(
    (link: string) => {
      if (token && tokenSiseci) {
        setIsAuth(true)
        window.open(link, '_blank', 'noreferrer')
      } else {
        setIsAuth(false)
      }
    },
    [setIsAuth, token, tokenSiseci],
  )

  return (
    <Box
      style={{
        gap: '1rem',
        width: '100%',
        display: 'flex',
        flexDirection: laptop ? 'column' : 'row',
        alignItems: laptop ? 'flex-start' : 'center',
        justifyContent: 'center',
      }}
    >
      {menuItems.map((menuItem) => (
        <ListItem
          key={menuItem.titulo}
          onClick={(e: React.SyntheticEvent): void => {
            e.preventDefault()
            if (menuItem.external_url && menuItem?.isAuth) {
              onBeforeNavigateVerifyIsAuthenticated(menuItem.external_url)
            } else if (menuItem.external_url && !menuItem?.isAuth) {
              window.open(menuItem.external_url)
            } else {
              history.push(menuItem.url)
            }
            handleClose()
          }}
          style={{
            padding: !laptop && 0,
            width: matches && 'auto',
          }}
        >
          <Typography
            className={
              matches ? classes.linksBoxSubMenu : classes.linksBoxSubMenu
            }
          >
            <menuItem.icon fontSize={16} />
            {menuItem.titulo}
          </Typography>
        </ListItem>
      ))}
    </Box>
  )
}
