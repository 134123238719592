import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import PrintIcon from '@material-ui/icons/Print'
import format from 'date-fns/format'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import colorContext from '../../Context/colorContext'
import { searchManifestation } from '../../services/ouvidoria'
import theme from '../../services/theme-service'
import { Manifestation } from '../ManifestationDetail'

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      color: '#1F2937',
      textTransform: 'capitalize',
      fontWeight: 700,
      fontFamily: 'Roboto',
      padding: '0px 16px',
      borderBottom: '1px solid #1F2937',
    },
    body: {
      fontSize: theme.typography.pxToRem(14),
      color: '#333333',
    },
  }),
)(TableCell)

export default function ManifestationDetailPrint(): JSX.Element {
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: 500,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      alignItems: 'center',
    },
    boxTitle: {
      maxWidth: 1320,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '0px auto 20px auto',
    },
    title: {
      fontSize: theme.typography.pxToRem(36),
      fontWeight: 800,
      color: '#1F2937',
    },
    boxButton: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 22px 8px 16px',
      border: '1px solid #DAE1EF',
      borderRadius: 4,
      gap: 8,
      cursor: 'pointer',
    },
    titleButton: {
      fontSize: theme.typography.pxToRem(15),
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: '#1F2937',
      textTransform: 'uppercase',
    },
    accordionDetail: {
      padding: '0px',
      wordBreak: 'break-word',
      backgroundColor: colors.colorBackgroundSecundary,
    },
    accordion: {
      border: '1px solid #DAE1EF',
      marginBottom: 20,
      width: '100%',
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
      '&::before': {
        display: 'none',
      },
      borderRadius: 4,
    },
    accordionRoot: {
      minHeight: 'auto !important',
      backgroundColor: '#F6F8FC',
      borderBottom: '1px solid #DAE1EF',
      margin: '0px !important',
    },
    accordionIcon: {
      width: 32,
      height: 32,
      color: '#1F2937',
      [theme.breakpoints.down(660)]: {
        width: 14,
        height: 16,
      },
    },
    boxContent: {
      // margin: '48px 32px 43px 32px',
      padding: 10,
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    titleAccordion: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 600,
      color: '#1F2937',
      margin: '0px !important',
      padding: '0px',
    },
    label: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      color: '#1F2937',
      marginBottom: 7,
    },
    value: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: '#1F2937',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    titleInfo: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      color: '#1F2937',
      marginBottom: 7,
    },
    description: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: '#1F2937',
      marginBottom: 10,
    },
    textInfo: {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 700,
      color: '#1F2937',
    },
    tableBorder: {
      border: '1px solid black',
    },
  }))
  const classes = useStyles()
  const [manifestationData, setManifestationData] = useState<Manifestation>()
  const [loading, setLoading] = useState<boolean>(true)
  const { codigo, protocolo }: any = useParams()
  const { state } = useLocation<any>()

  useEffect(() => {
    const captcha = document.getElementsByClassName('grecaptcha-badge')

    if (captcha && captcha.length) {
      captcha[0].remove()
    }
  }, [])

  useEffect(() => {
    async function getManifestationData() {
      try {
        setLoading(true)
        const { data } = await searchManifestation(
          codigo,
          protocolo,
          state?.email,
          state?.cpf,
        )
        setManifestationData(data[0])
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    const enable = document.getElementsByClassName('enabled')
    if (enable && enable.length) {
      enable[0].remove()
    }
    if (codigo && protocolo) {
      getManifestationData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigo, protocolo])

  useEffect(() => {
    if (!loading) {
      window.print()
    }
  }, [loading])

  return (
    <Box style={{ padding: 20, backgroundColor: '#fff' }} id="print">
      <Box className={classes.boxTitle}>
        <Typography className={classes.title}>
          Detalhes da Manifestação
        </Typography>
      </Box>
      {loading && <CircularProgress />}
      {!loading && manifestationData && (
        <Box width="100%" maxWidth="1320px" marginBottom="100px">
          <Accordion defaultExpanded className={classes.accordion}>
            <AccordionSummary
              classes={{
                root: classes.accordionRoot,
                expanded: classes.accordionRoot,
              }}
            >
              <Typography className={classes.titleAccordion}>
                Dados Gerais da Manifestação
              </Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetail }}>
              <Box className={classes.boxContent}>
                <Grid container spacing={3}>
                  <Grid item sm={3}>
                    <Typography className={classes.label}>Protocolo</Typography>
                    <span className={classes.value}>
                      {manifestationData?.numero_processo || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className={classes.label}>
                      Tipo de Identificação
                    </Typography>
                    <span className={classes.value}>
                      {manifestationData?.tipo_identificacao ||
                        'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className={classes.label}>Assunto</Typography>
                    <span className={classes.value}>
                      {manifestationData?.assunto?.titulo || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className={classes.label}>
                      Data de Registro
                    </Typography>
                    <span className={classes.value}>
                      {format(
                        new Date(manifestationData?.created_at),
                        'dd/MM/yyyy',
                      ) || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className={classes.label}>
                      Código de Acesso
                    </Typography>
                    <span className={classes.value}>{codigo}</span>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className={classes.label}>
                      Órgão Destinatário
                    </Typography>
                    <span className={classes.value}>
                      {manifestationData?.orgao?.nome || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className={classes.label}>
                      Canal de entrada
                    </Typography>
                    <span className={classes.value}>
                      {manifestationData?.canal_entrada || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className={classes.label}>
                      Prazo de Resposta
                    </Typography>
                    <span className={classes.value}>
                      {format(
                        new Date(manifestationData?.vencimento),
                        'dd/MM/yyyy',
                      ) || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className={classes.label}>
                      Tipo de Manifestação
                    </Typography>
                    <span className={classes.value}>
                      {manifestationData?.tipo_manifestacao?.titulo ||
                        'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className={classes.label}>Serviço</Typography>
                    <span className={classes.value}>
                      {manifestationData?.servico_titulo || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className={classes.label}>
                      Local do Fato
                    </Typography>
                    <span className={classes.value}>
                      {manifestationData?.local || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className={classes.label}>Situação</Typography>
                    <span className={classes.value}>
                      {manifestationData?.status?.status || 'Sem resultados.'}
                    </span>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded className={classes.accordion}>
            <AccordionSummary
              classes={{
                root: classes.accordionRoot,
                expanded: classes.accordionRoot,
              }}
            >
              <Typography className={classes.titleAccordion}>
                Teor da Manifestação
              </Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetail }}>
              <Box className={classes.boxContent}>
                <Grid container spacing={1} direction="column">
                  <Grid item sm={4}>
                    <Typography className={classes.label}>
                      Texto da Manifestação
                    </Typography>
                    <span className={classes.value}>
                      {manifestationData?.conteudo || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item sm={4}>
                    <Typography className={classes.label}>Anexo</Typography>
                    {manifestationData?.anexos.length > 0 ? (
                      <Box
                        display="flex"
                        alignItems="flex-start"
                        style={{
                          gap: 10,
                        }}
                      >
                        {manifestationData?.anexos.map((anexo) => (
                          <a
                            className={classes.value}
                            key={anexo.id}
                            href={anexo.arquivo}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {anexo.titulo}
                          </a>
                        ))}
                      </Box>
                    ) : (
                      <span className={classes.value}>Sem anexos</span>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded className={classes.accordion}>
            <AccordionSummary
              classes={{
                root: classes.accordionRoot,
                expanded: classes.accordionRoot,
              }}
            >
              <Typography className={classes.titleAccordion}>
                Histórico da Manifestação
              </Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetail }}>
              <Box width="100%">
                <TableContainer>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">Ordem</StyledTableCell>
                        <StyledTableCell align="left">
                          Data/Hora
                        </StyledTableCell>
                        <StyledTableCell align="left">Órgão</StyledTableCell>
                        {/* <StyledTableCell align="left">Emitido</StyledTableCell> */}
                        <StyledTableCell align="left">Status</StyledTableCell>
                        <StyledTableCell align="right" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {manifestationData?.status?.historico_status?.map(
                        (historico, index) => (
                          <Fragment key={`${historico.ordem}-key`}>
                            <TableRow>
                              <StyledTableCell align="left">
                                {historico.ordem}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {`${format(
                                  new Date(historico.created_at),
                                  'dd/MM/yyyy',
                                )} às ${format(
                                  new Date(historico.created_at),
                                  'HH:mm',
                                )}`}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {historico.orgao_nome ?? 'Teste'}
                              </StyledTableCell>
                              {/* <StyledTableCell align="left">
                            {manifestationData?.}
                          </StyledTableCell> */}
                              <StyledTableCell align="left">
                                {historico.status}
                              </StyledTableCell>
                              <StyledTableCell>
                                {/* <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => expandTable(index)}
                                >
                                  {showDetails === index ? (
                                    <ExpandLessIcon />
                                  ) : (
                                    <ExpandMoreIcon />
                                  )}
                                </IconButton> */}
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={6}
                              >
                                <Collapse in timeout="auto" unmountOnExit>
                                  <Box margin="10px 0px">
                                    <Typography className={classes.titleInfo}>
                                      Descrição
                                    </Typography>
                                    <Typography className={classes.description}>
                                      {historico.descricao || 'Sem resultados.'}
                                    </Typography>
                                    {historico.status === 'Respondido' &&
                                      manifestationData?.status?.anexos_status
                                        .length > 0 && (
                                        <>
                                          <Typography
                                            className={classes.titleInfo}
                                          >
                                            Anexos
                                          </Typography>
                                          <Box
                                            display="flex"
                                            alignItems="flex-start"
                                            style={{
                                              gap: 10,
                                            }}
                                          >
                                            {manifestationData?.status?.anexos_status.map(
                                              (anexo) => (
                                                <a
                                                  key={anexo.id}
                                                  href={anexo.arquivo}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className={classes.value}
                                                >
                                                  {anexo.titulo}
                                                </a>
                                              ),
                                            )}
                                          </Box>
                                        </>
                                      )}
                                  </Box>
                                </Collapse>
                              </StyledTableCell>
                            </TableRow>
                          </Fragment>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Grid container justify="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className="print_none"
                onClick={() => window.print()}
              >
                <style>{'@media print {.print_none{display: none;}}'}</style>{' '}
                <PrintIcon />
                <Typography>IMPRIMIR</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  )
}
