/* eslint-disable @typescript-eslint/ban-types */
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Theme from '@material-ui/core/styles/MuiThemeProvider'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import HelpIcon from '@material-ui/icons/Help'
import RemoveIcon from '@material-ui/icons/Remove'
import { makeStyles } from '@material-ui/styles'
import React, { useContext, useEffect, useState } from 'react'

import colorContext from '../../Context/colorContext'
import { FAQOuvidoria } from '../../services/ouvidoria'
import { Breadcrumb } from './components'

interface ListQuestion {
  ordem: number
  titulo: string
  conteudo: string
}

export default function FAQ(): JSX.Element {
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 1000,
      backgroundColor: colors.colorBackground,
    },
    containerTitle: {
      width: '100%',
      backgroundColor: colors.accentColorBackground,
    },
    boxTitle: {
      maxWidth: 1320,
      minHeight: 276,
      height: '100%',
      width: '100%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 40,
      [theme.breakpoints.down('xs')]: {
        minHeight: 215,
        gap: 24,
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(32),
      color: colors.textAccentColor,
      fontWeight: 700,
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(24),
      },
    },
    iconTitle: {
      width: 70,
      height: 70,
      color: colors.colorBackground,
      [theme.breakpoints.down('xs')]: {
        width: 40,
        height: 40,
      },
    },
    content: {
      maxWidth: 1320,
      width: '100%',
      margin: '0 auto',
      padding: '55px 10px 104px 10px',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },

    loading: {
      display: 'flex',
      justifyContent: 'center',
    },

    accordion: {
      backgroundColor: colors.colorBackgroundSecundary,
      marginBottom: 20,
      border: `1px solid ${colors.borderShadowsColor}`,
      width: '100%',
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
      '&::before': {
        display: 'none',
      },
      borderRadius: 4,
      borderLeftWidth: '4px',
      borderLeftColor: '#053396',
    },
    accordionRoot: {
      minHeight: 62,
    },
    titleAccordion: {
      fontSize: theme.typography.pxToRem(18),
      color: '#1F2937',
      fontWeight: 600,
      marginLeft: 6,
      fontFamily: 'Roboto',
    },
    accordionDetail: {
      padding: '0px 42px 16px 16px',
      wordBreak: 'break-word',
    },
    colorIcon: {
      color: colors.accentColor,
      width: 21,
      height: 24,
      [theme.breakpoints.down(660)]: {
        width: 14,
        height: 16,
      },
    },
    text: {
      fontSize: theme.typography.pxToRem(16),
      color: '#1F2937',
      fontWeight: 400,
      fontFamily: 'Roboto',
      lineHeight: '25.6px',
    },
  }))

  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [listQuestions, setListQuestions] = useState<ListQuestion[]>([])
  const [expanded, setExpanded] = useState<string | false>('panel1')

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const getFAQOuvidoria = async () => {
    setLoading(true)

    try {
      const { data } = await FAQOuvidoria()
      setListQuestions(data)
      setLoading(false)
    } catch {
      setLoading(true)
    }
  }

  useEffect(() => {
    getFAQOuvidoria()
  }, [])

  return (
    <Box className={classes.main}>
      <Breadcrumb slug="ouvidoria" />
      <Box className={classes.containerTitle}>
        <Box className={classes.boxTitle}>
          <HelpIcon className={classes.iconTitle} />
          <Typography className={classes.title}>
            Perguntas Frequentes
          </Typography>
        </Box>
      </Box>
      <Box className={classes.content}>
        {loading ? (
          <Box className={classes.loading}>
            <CircularProgress />
          </Box>
        ) : (
          listQuestions.map((question) => (
            <Accordion
              key={question.ordem}
              expanded={expanded === `panel${question.ordem}`}
              onChange={handleChange(`panel${question.ordem}`)}
              className={classes.accordion}
            >
              <AccordionSummary
                classes={{ root: classes.accordionRoot }}
                expandIcon={
                  expanded === `panel${question.ordem}` ? (
                    <RemoveIcon className={classes.colorIcon} />
                  ) : (
                    <AddIcon className={classes.colorIcon} />
                  )
                }
              >
                <Typography className={classes.titleAccordion}>
                  {question.ordem} - {question.titulo}
                </Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetail }}>
                <Typography className={classes.text}>
                  {question.conteudo}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Box>
    </Box>
  )
}
