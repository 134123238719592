/* eslint-disable react/require-default-props */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Cancel from '@material-ui/icons/Cancel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import colorContext from '../../../Context/colorContext'

interface Props {
  typeError: boolean
  errorMessage?: string
}

export default function InfoMessage({
  typeError,
  errorMessage,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles(() => ({
    boxInfoConclueded: {
      marginTop: 50,
      width: 668,
      borderRadius: 4,
      border: '1px solid #E1E6EF',
      backgroundColor: colors.colorBackground,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
    },
    iconBox: {
      margin: '68px 0px 36px 0px',
    },
    textBox: {
      fontFamily: 'Roboto',
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '28px',
      textAlign: 'center',
    },
    subTextBox: {
      maxWidth: 343,
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'center',
      margin: '16px 0px 48px 0px',
    },
    buttonRedirect: {
      backgroundColor: colors.accentColorBackground,
      color: colors.textAccentColor,
      textTransform: 'uppercase',
      padding: '17px 22px',
      height: 48,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 700,
      textAlign: 'center',
      '&:hover': {
        backgroundColor: colors.accentColorBackground,
      },
    },
  }))
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box
      className={classes.boxInfoConclueded}
      height={typeError ? 'auto' : 424}
    >
      <Box className={classes.iconBox}>
        {typeError ? (
          <Cancel style={{ color: '#F03D3D', fontSize: 46 }} />
        ) : (
          <CheckCircle style={{ color: '#0BB07B', fontSize: 46 }} />
        )}
      </Box>
      {typeError ? (
        <>
          <Typography className={classes.textBox}>Algo deu errado!</Typography>
          <Typography className={classes.subTextBox}>
            {errorMessage ||
              'Verifique seus dados corretamente e tente novamente.'}{' '}
          </Typography>
        </>
      ) : (
        <>
          <Typography className={classes.textBox}>
            Reagendamento concluído
          </Typography>
          <Typography className={classes.subTextBox}>
            Você receberá um e-mail e uma mensagem com a confirmação do seu
            agendamento.
          </Typography>
          <Box>
            <Button
              id="confirm-agendamento"
              type="button"
              className={classes.buttonRedirect}
              onClick={() => history.push('/')}
            >
              ir para o portal
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}
