/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import DateFnsUtils from '@date-io/date-fns'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {
  createGenerateClassName,
  makeStyles,
  StylesProvider,
  Theme,
} from '@material-ui/core/styles'
import ThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import Typography from '@material-ui/core/Typography'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import EventIcon from '@material-ui/icons/Event'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import ptBR from 'date-fns/locale/pt-BR'
import subDays from 'date-fns/subDays'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import colorContext from '../../Context/colorContext'
import { SearchI } from '../../Pages/SearchService'
import { Holliday } from '../../Pages/ServiceInfo/components/ModalAttendancePresential'
import {
  agendar,
  cancelar,
  checarHorarios,
  feriados,
  getCheckInterval,
  getDependentes,
} from '../../services/agendamento'
import themeCalendarProvider from '../../services/calendarTheme'
import { removeCnpjMask } from '../../utils/cnpjFormat'
import { removeCpfMask, validateCpf } from '../../utils/cpfFormat'
import getCookie from '../../utils/getCookies'
import SchedulingAssessment from '../SchedulingAssessment'

interface SavedDependenteProps {
  agendamento: number
  cpf: string
  cpf_solicitante: string
  data_nascimento: string
  grau_parentesco: string
  id: number
  nome: string
}

export interface UserData {
  cns: string
  contato: {
    celular: string
    email: string
    email_verificado: boolean
    pessoa: string
    telefone: string
  }
  cpf: string
  created_at: string
  data_nascimento: string
  data_obito: string
  deficiente: boolean
  endereco: []
  estado_civil: string
  identificacao_social: string
  motivo_obito: string
  nacionalidade: string
  naturalidade: string
  nome: string
  nome_mae: string
  nome_pai: string
  nome_social: string
  orgao_expedidor: string
  raca_cor: string
  responsavel: string
  rg: string
  rg_uf: string
  sexo: string
  tipo_deficiente: string
  uf_naturalidade: string
  updated_at: string
}
interface DependenteProps {
  cpf: string
  data_nascimento: Date
  email: string
  id: number
  nome_completo: string
  pcd: boolean
  pessoa_dependente: {
    parentesco: string
  }[]
}
interface SavedScheduleProps {
  cpf: string
  data: string
  data_solicitacao: string
  guiche: number
  hora: string
  hora_inicio: string
  id: number
  para_dependente: boolean
  prioridade: boolean
  servico: number
  setor: string
  status: string
  ticket: string
  tipo: string
  unidade: string
  updated_at: string
  user: number
}

export interface Schedule {
  horarios: string[]
}

interface Props {
  serviceSelected: SearchI
  hasDependente: boolean
  cnpjNumber: string
  handleClose: () => void
  hasSaved: (value: boolean) => void
  hasCompleted: (value: boolean) => void
  handleRemoveService: () => void
  handleHasScheduler: () => void
}

export default function AttendencePresential({
  serviceSelected,
  hasDependente,
  cnpjNumber,
  handleClose,
  hasSaved,
  hasCompleted,
  handleRemoveService,
  handleHasScheduler,
}: Props): JSX.Element {
  const { colors, typeTheme } = useContext(colorContext)
  const generateClassName = createGenerateClassName({
    productionPrefix: 'presencial',
    disableGlobal: true,
  })
  const materialTheme = themeCalendarProvider(colors, typeTheme)
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: '#d3d3d3',
        },
      },
      '& .MuiSelect-iconOutlined': {
        height: 24,
        width: 24,
      },
    },
    header: {
      background: theme.palette.primary.main,
    },
    textHeader: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      width: '90%',
    },
    formControl: {
      marginTop: 20,
      marginBottom: 20,
      // margin: theme.spacing(1),
      width: '100%',
    },
    menuItem: {
      color: theme.palette.primary.main,
    },
    title: {
      fontSize: '16px',
      fontStyle: 'Molish',
      color: '#373F41',
      marginTop: 10,
      fontWeight: 700,
      lineHeight: '20px',
    },
    buttonClose: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: '#fff',
    },
    textSchedule: {
      color: '#373F41',
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(20),
      marginTop: 30,
    },
    textSubtitleSchedule: {
      color: '#373F41',
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      marginTop: 20,
    },
    iconUpload: {
      color: '#9E9E9E',
    },
    rootUpload: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 140,
      flexDirection: 'column',
    },
    adornedEnd: {
      paddingRight: 0,
    },
    inputSearch: {
      maxWidth: 582,
      width: '100%',
      height: 48,
      border: `1px solid ${theme.palette.primary.main}`,
      outline: 'none',
      paddingLeft: 16,
      fontFamily: 'Roboto',
      fontSize: 14,
      borderRadius: 0,
    },
    optionsList: {
      marginTop: '16px',
      width: '100%',
      border: '0.5px solid #DADCE0',
      maxWidth: '540px',
      height: '48px',
      borderLeftWidth: '2px',
      borderLeftColor: `${theme.palette.primary.main}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 5px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    optionsListText: {
      fontSize: '14px',
      color: '#373F41',
      fontWeight: 600,
      fontFamily: 'Roboto',
    },
    boxnNextOptions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '20px',
    },
    serviceSelectedTextTitle: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontFamily: 'Roboto',
      textTransform: 'uppercase',
      fontSize: '14px',
    },
    serviceSelectedText: {
      color: '#373F41',
      fontWeight: 600,
      fontFamily: 'Roboto',
      fontSize: '16px',
      marginTop: '16px',
    },
    serviceSelectedSubText: {
      color: '#373F41',
      fontFamily: 'Roboto',
      fontWeight: 300,
      fontSize: '16px',
    },
    buttonAddArchives: {
      backgroundColor: `${theme.palette.primary.main}`,
      height: '40px',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: '#0A5D9C',
      },
    },
    boxConfirmScheduling: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px',
    },
    buttonConfirmScheduling: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,
      color: '#FFFFFF',
      width: '300px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    inputCheckbox: {
      color: '#373F41',
    },
    inputCheckboxText: {
      fontFamily: 'Roboto',
      fontSize: 14,
      fontstyle: 'normal',
      fontWeight: 400,
      textAlign: 'left',
      color: 'rgba(55, 63, 65, 0.6)',
    },
    colorInputText: {
      color: theme.palette.primary.main,
    },
    box: {
      marginBottom: 20,
    },
    titleBox: {
      fontSize: theme.typography.pxToRem(14),
      color: '#333333',
      fontWeight: 500,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      margin: '2px 0px 10px 0px',
    },
    boxInfoConcluded: {
      borderRadius: 4,
      padding: '16px 24px',
      border: '1px solid #0BB07B',
      backgroundColor: 'rgba(11, 176, 123, 0.05)',
      minHeight: 109,
      marginTop: 16,
    },
    infoConcluded: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    textInfoUnity: {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 400,
      color: '#706E7A',
      marginTop: 16,
      marginBottom: 8,
    },
    textInfoDateTime: {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 400,
      color: '#706E7A',
      marginBottom: 2,
    },
    buttonPrimary: {
      margin: '10px 0px',
      backgroundColor: colors.accentColorBackground,
      color: colors.textAccentColor,
      width: 186,
      height: 44,
      '&:hover': {
        backgroundColor: colors.accentColorBackground,
      },
    },
    textButtonPrimary: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    buttonOutlined: {
      width: 186,
      height: 44,
      padding: '14px 16px',
      marginLeft: '0px !important',
    },
    textButtonOutlined: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.accentColor,
      fontFamily: 'Roboto',
      fontWeight: 700,
      textTransform: 'uppercase',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    textSubtitleScheduleBox: {
      width: 165,
      height: 30,
      borderRadius: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(225, 230, 239, 0.3)',
      color: '#333333',
    },
    iconArrow: {
      color: theme.palette.primary.main,
      width: 24,
      height: 24,
    },
    icon: {
      width: 24,
      height: 24,
    },
    iconLarge: {
      width: 80,
      height: 80,
    },
    boxCancel: {
      backgroundColor: colors.colorBackgroundSecundary,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 20,
    },
    titleCancel: {
      color: colors.textBody,
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(28),
    },
    boxActionsCancel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 24px',
      margin: '20px 0px',
    },
    textUnidade: {
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '23px',
      textTransform: 'capitalize',
      color: '#737B7D',
      paddingBottom: '20px',
      paddingTop: '20px',
    },
    textService: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '22px',
      color: '#373F41',
    },
    textOrgan: {
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '22px',
      color: '#373F41',
      paddingBottom: '20px',
    },
    textDateTime: {
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#373F41',
    },
    concludedContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    inputCheckboxTextAlert: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      textAlign: 'left',
      color: colors.textBody,
    },
  }))

  const classes = useStyles()
  const history = useHistory()
  // const [serviceSelected, setServiceSelected] = useState<SearchI>();
  const [unitySelected, setUnitySelected] = useState<string>('')
  const [selectedDate, handleDateChange] = useState<Date | null>(null)
  const [scheduleSelected, setScheduleSelected] = useState<string>('')
  const [scheduleService, setScheduleService] = useState<
    string | Schedule | undefined
  >()
  const [scheduleServiceUser, setScheduleServiceUser] = useState<
    SavedScheduleProps | string | undefined
  >()
  const [, setHolliday] = useState<Holliday>()
  const [loading, setLoading] = useState<boolean>(false)
  const [cpfDependente, setCpfDependente] = useState<string>('')
  const [checkedDependente, setCheckedDependente] = useState(false)
  const [, setErrorCpfDependente] = useState<undefined | string>(undefined)
  const [nameDependente, setNameDependente] = useState<string>('')
  const [, setErrorNameDependente] = useState<undefined | string>(undefined)
  const [birthDateDependente, setBirthDateDependente] = useState<Date>(
    subDays(new Date(), 30),
  )
  const [dependenteSelected, setDependenteSelected] = useState<string>('')
  const [savedSchedule, setSavedSchedule] =
    useState<SavedScheduleProps>(undefined)
  const [savedDependente, setSavedDependente] =
    useState<SavedDependenteProps>(undefined)
  const [showBoxData, setShowBoxData] = useState(false)
  const [dependentesRegistrados, setDependentesRegistrados] = useState([])
  const [dependenteSelecionado, setDependenteSelecionado] =
    useState<DependenteProps>()
  const [hiddenInfoShedule, setHiddenInfoSchedule] = useState(true)
  const [loadingDepente] = useState(false)
  const [, setOpenUploads] = useState<boolean>(false)
  const [hiddenCancelData, setHiddenCancelData] = useState(true)
  const [hiddenWaring, setHiddenWaring] = useState(false)
  const [cancelSchedule, setCancelSchedule] = useState(false)
  const [loadingCancel, setLoadingCancel] = useState(false)
  const [evaluationCompleted, setEvaluationCompleted] = useState(true)
  const [availableDates, setAvailableDates] = useState<string[]>([])
  const handleCheckDependente = () => {
    setCheckedDependente(!checkedDependente)
  }

  const getHollidays = async () => {
    const { data } = await feriados()
    setHolliday(data)
  }

  const getSchedulers = async (date: Date, unity: string): Promise<void> => {
    setScheduleService(undefined)
    setScheduleServiceUser(undefined)
    if (isValid(date) && serviceSelected) {
      try {
        const { data } = await checarHorarios(
          date,
          unity,
          serviceSelected.id,
          'Presencial',
        )
        setScheduleService(data)
      } catch (err) {
        if (err.response) {
          setScheduleService(err.response.data.message)
        } else {
          setScheduleService('Algo inesperado ocorreu')
        }
      }
    } else {
      setScheduleService(undefined)
    }
  }
  useEffect(() => {
    setNameDependente(dependenteSelecionado?.nome_completo)
    setCpfDependente(dependenteSelecionado?.cpf)
    setBirthDateDependente(dependenteSelecionado?.data_nascimento)
    setDependenteSelected(
      dependenteSelecionado?.pessoa_dependente[0]?.parentesco,
    )
  }, [dependenteSelecionado])
  const getUserDependentes = async (cpf: string) => {
    const token: string | null = getCookie('gov_access_token_sso')

    try {
      const data = await getDependentes(token, cpf)
      setDependentesRegistrados(data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const selectDependente = (id: number) => {
    const dependenteFiltrado = dependentesRegistrados.filter(
      (dependente) => dependente.id === id,
    )
    return setDependenteSelecionado(dependenteFiltrado[0])
  }
  const schedulerService = async (): Promise<void> => {
    setLoading(true)
    const token: string | null = getCookie('gov_access_token_sso')
    const dataStorage: string | null = getCookie('gov_user_data')
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null
    if (token && userData && serviceSelected) {
      try {
        let dependentes

        if (checkedDependente) {
          const cpfDependenteWithoutMask = removeCpfMask(cpfDependente)

          if (nameDependente && nameDependente.length < 6) {
            setErrorNameDependente('Nome deve ter no mínimo 6 caracteres.')
            return
          }

          if (cpfDependenteWithoutMask) {
            if (
              !validateCpf(cpfDependenteWithoutMask) ||
              cpfDependenteWithoutMask.length < 11
            ) {
              setErrorCpfDependente('CPF inválido.')
              return
            }
          }
          dependentes = {
            cpf: cpfDependente,
            nome: nameDependente,
            data_nascimento: birthDateDependente,
            cpf_solicitante: userData?.cpf,
            grau_parentesco: dependenteSelected,
          }
        }

        const cnpj = cnpjNumber && removeCnpjMask(cnpjNumber)

        const { status, data } = await agendar(
          token,
          userData.cpf,
          serviceSelected.id,
          unitySelected,
          serviceSelected.setor_id,
          'Presencial',
          selectedDate,
          scheduleSelected,
          cnpjNumber ? 'cnpj' : undefined,
          cnpj,
          null,
          null,
          checkedDependente,
          dependentes,
        )
        setScheduleServiceUser(data)

        if (status === 201) {
          setSavedSchedule(data)
          hasSaved(true)
          setHiddenInfoSchedule(false)
          setShowBoxData(true)
          hasCompleted(true)
          handleHasScheduler()
        }

        setHiddenInfoSchedule(false)
        setShowBoxData(true)
        hasCompleted(true)
      } catch (err) {
        if (err?.response?.data?.length > 0) {
          setScheduleServiceUser(err?.response?.data[0])
        } else if (err?.response?.data) {
          setScheduleServiceUser(
            err.response?.data?.message ||
              err.response?.data?.detail ||
              'Algo inesperado ocorreu no agendamento.',
          )
          setErrorNameDependente(
            err.response?.data?.detail ||
              'Algo inesperado ocorreu no agendamento do dependente.',
          )
        } else {
          setScheduleServiceUser('Algo inesperado ocorreu.')
        }
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleCancelAttendance = async (id: string | number) => {
    setLoadingCancel(true)
    const token: string | null = getCookie('gov_access_token_sso')
    const dataStorage: string | null = getCookie('gov_user_data')
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null
    if (token && userData) {
      await cancelar(token, id)
      setLoadingCancel(false)
      setShowBoxData(true)
      setCancelSchedule(true)
    }
  }
  const handleClearHistoryInfo = () => {
    setUnitySelected('')
    setScheduleSelected('')
    setCheckedDependente(false)
    setSavedSchedule(undefined)
    setSavedDependente(undefined)
    setScheduleServiceUser(undefined)
    handleDateChange(null)
    setUnitySelected('')
    setHiddenCancelData(true)
    handleRemoveService()
  }

  const handleActiveDriveCheck = useMemo(() => {
    if (serviceSelected?.servicosUnidade?.length > 0) {
      return (
        serviceSelected.servicosUnidade.filter((unity) => unity.unidade.ativo)
          .length === 0
      )
    }
    return true
  }, [serviceSelected])

  const isDateAvailable = (date: Date) => {
    const dateFormatted = date.toISOString().split('T')[0]
    return availableDates.includes(dateFormatted)
  }

  const handleCheckInterval = async (date?: Date) => {
    const token: string | null = getCookie('gov_access_token_sso')
    const servico = serviceSelected?.id
    const unidade = unitySelected
    const dataInicio = !date
      ? new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1,
        ).toISOString()
      : date.toISOString()
    const dataFim = !date
      ? new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0,
        ).toISOString()
      : new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString()
    const tipo = 'Presencial'

    const response = await getCheckInterval(
      token,
      servico,
      unidade,
      dataInicio.split('T')[0],
      dataFim.split('T')[0],
      tipo,
    )

    if (response.status === 200) {
      setAvailableDates(response.data)
    }
  }

  useEffect(() => {
    if (serviceSelected && unitySelected) {
      handleCheckInterval()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceSelected, unitySelected])

  useEffect(() => {
    const dataStorage: string | null = getCookie('gov_user_data')
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null
    setUnitySelected('')
    setScheduleSelected('')
    setScheduleService(undefined)
    setScheduleServiceUser(undefined)
    setOpenUploads(false)
    getHollidays()
    setSavedSchedule(undefined)
    hasSaved(false)
    setSavedDependente(undefined)
    getUserDependentes(userData?.cpf)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    <>
      {serviceSelected && (
        <Box>
          <div hidden={showBoxData}>
            <DialogContent>
              {loading ? (
                <Box style={{ marginTop: 20, textAlign: 'center' }}>
                  <CircularProgress color="primary" />
                </Box>
              ) : (
                <>
                  {!savedSchedule && (
                    <>
                      <Box className={classes.box}>
                        <Typography className={classes.titleBox}>
                          Faça seu agendamento
                        </Typography>
                        <div id="input-check">
                          {!hasDependente && (
                            <FormControlLabel
                              className={classes.inputCheckbox}
                              control={
                                <Checkbox
                                  checked={checkedDependente}
                                  onChange={handleCheckDependente}
                                  name="dependente"
                                  style={{ color: colors.accentColor }}
                                />
                              }
                              label="Dependente"
                            />
                          )}
                          {!hasDependente ? (
                            <Typography className={classes.inputCheckboxText}>
                              Selecione caso este agendamento seja para seu
                              dependente.
                            </Typography>
                          ) : (
                            <Typography
                              className={classes.inputCheckboxTextAlert}
                            >
                              Agendamento apenas para o titular.
                            </Typography>
                          )}
                        </div>
                        <div id="input-unidade">
                          <FormControl
                            classes={{ root: classes.root }}
                            variant="outlined"
                            className={classes.formControl}
                            disabled={handleActiveDriveCheck}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Selecione a unidade
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              disabled={handleActiveDriveCheck}
                              onChange={(event): void => {
                                setUnitySelected(event.target.value as string)
                                getSchedulers(
                                  selectedDate,
                                  event.target.value as string,
                                )
                              }}
                              label="Selecione a unidade"
                              classes={{
                                select: classes.menuItem,
                              }}
                            >
                              {serviceSelected.servicosUnidade
                                .filter((unity) => unity.unidade.ativo)
                                .map((unity) => (
                                  <MenuItem
                                    key={unity.id}
                                    className={classes.menuItem}
                                    value={unity.unidade.slug_unidade}
                                  >
                                    {unity.unidade.nome}
                                  </MenuItem>
                                ))}
                            </Select>
                            {handleActiveDriveCheck && (
                              <FormHelperText error>
                                Não há unidades disponíveis.
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div id="input-data">
                          <FormControl
                            classes={{ root: classes.root }}
                            error={
                              typeof scheduleService === 'string' ||
                              typeof scheduleServiceUser === 'string'
                            }
                            disabled={!unitySelected}
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <StylesProvider
                              generateClassName={generateClassName}
                            >
                              <ThemeProvider theme={materialTheme}>
                                <MuiPickersUtilsProvider
                                  locale={ptBR}
                                  utils={DateFnsUtils}
                                >
                                  <KeyboardDatePicker
                                    keyboardIcon={
                                      <EventIcon className={classes.icon} />
                                    }
                                    disableFuture={false}
                                    error={
                                      typeof scheduleService === 'string' ||
                                      typeof scheduleServiceUser === 'string'
                                    }
                                    minDateMessage="Você não pode agendar em dias anteriores"
                                    invalidDateMessage="A data informada é inválida"
                                    minDate={new Date()}
                                    InputProps={{
                                      className: classes.menuItem,
                                      classes: {
                                        adornedEnd: classes.adornedEnd,
                                      },
                                    }}
                                    autoOk
                                    disabled={!unitySelected}
                                    variant="inline"
                                    inputVariant="outlined"
                                    label="Selecione o dia"
                                    shouldDisableDate={(date) =>
                                      !isDateAvailable(date)
                                    }
                                    onMonthChange={async (date) =>
                                      handleCheckInterval(date)
                                    }
                                    placeholder="00/00/0000"
                                    format="dd/MM/yyyy"
                                    value={selectedDate || null}
                                    InputAdornmentProps={{
                                      position: 'end',
                                      color: '#000',
                                    }}
                                    onChange={(date) => {
                                      if (date && isValid(date)) {
                                        handleDateChange(date)
                                        if (unitySelected) {
                                          getSchedulers(date, unitySelected)
                                        }
                                      }
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </ThemeProvider>
                            </StylesProvider>
                            {typeof scheduleService === 'string' && (
                              <FormHelperText>{scheduleService}</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div id="input-horario">
                          <FormControl
                            classes={{ root: classes.root }}
                            disabled={
                              !unitySelected ||
                              !selectedDate ||
                              typeof scheduleService === 'string'
                            }
                            variant="outlined"
                            className={classes.formControl}
                            error={typeof scheduleServiceUser === 'string'}
                          >
                            <InputLabel id="demo-simple-select-filled-label">
                              Escolha o horário
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              classes={{
                                select: classes.menuItem,
                              }}
                              // value={age}
                              onChange={(event): void => {
                                setScheduleSelected(
                                  event.target.value as string,
                                )
                              }}
                              label="Escolha o horário"
                            >
                              {typeof scheduleService !== 'string' &&
                                scheduleService?.horarios.map((schedule) => (
                                  <MenuItem
                                    key={schedule}
                                    className={classes.menuItem}
                                    value={schedule}
                                  >
                                    {schedule}
                                  </MenuItem>
                                ))}
                            </Select>
                            {typeof scheduleServiceUser === 'string' && (
                              <FormHelperText>
                                {scheduleServiceUser}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          {checkedDependente && (
                            <>
                              {loadingDepente ? (
                                <Box
                                  style={{ marginTop: 20, textAlign: 'center' }}
                                >
                                  <CircularProgress color="primary" />
                                </Box>
                              ) : (
                                <>
                                  {!savedDependente && (
                                    <>
                                      <Typography className={classes.titleBox}>
                                        dados do dependente
                                      </Typography>
                                      <FormControl
                                        classes={{ root: classes.root }}
                                        variant="outlined"
                                        className={classes.formControl}
                                      >
                                        <InputLabel id="demo-simple-select-outlined-label">
                                          Dependentes
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          onChange={(event): void => {
                                            selectDependente(
                                              event.target.value as number,
                                            )
                                          }}
                                          // disabled={!!dependente.pessoa_dependente?.[0]?.parentesco}
                                          // value={dependente?.pessoa_dependente?.[0]?.parentesco || parentesco}
                                          label="Dependentes"
                                          classes={{
                                            select: classes.menuItem,
                                          }}
                                        >
                                          {dependentesRegistrados &&
                                            dependentesRegistrados?.map(
                                              (unity) => (
                                                <MenuItem
                                                  key={unity.id}
                                                  className={classes.menuItem}
                                                  value={unity.id}
                                                >
                                                  {unity.nome_completo}
                                                </MenuItem>
                                              ),
                                            )}
                                        </Select>
                                      </FormControl>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          <Box
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: 20,
                              marginBottom: 20,
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={handleClearHistoryInfo}
                              style={{
                                backgroundColor: colors.buttonErrorColor,
                                color: colors.textAccentColor,
                              }}
                            >
                              Cancelar
                            </Button>
                            <Button
                              style={{
                                textTransform: 'uppercase',
                                color: '#fff',
                                borderRadius: 4,
                                marginTop: 20,
                                marginBottom: 20,
                                alignSelf: 'flex-end',
                              }}
                              disabled={
                                !unitySelected ||
                                !selectedDate ||
                                !scheduleSelected ||
                                typeof scheduleService === 'string' ||
                                typeof scheduleService === 'undefined'
                              }
                              color="primary"
                              id="salvar-agendamento"
                              variant="contained"
                              onClick={schedulerService}
                            >
                              Salvar
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                </>
              )}
            </DialogContent>

            {/* <Box className={classes.boxConfirmScheduling}>
              <Button
                className={classes.buttonConfirmScheduling}
                disabled={
                  loading ||
                    !unitySelected ||
                    !selectedDate ||
                    !scheduleSelected ||
                    typeof scheduleService === "string" ||
                    typeof scheduleService === "undefined"
                    || (checkedDependente) ? (!checkedDependente || !nameDependente || !cpfDependente || !birthDateDependente || !dependenteSelected) : false
                }
                id="confirm-agendamento"
                variant="contained"
                onClick={schedulerService}
                color="primary"
              >
                {loading ? <CircularProgress style={{ width: 25, height: 25 }} color="inherit" /> : "Confirmar Agendamento"}
              </Button>
            </Box> */}

            {/*  aqui é a parte inicial da lista de serviços, só descomentar */}
            {savedSchedule && (
              <DialogActions
                style={{
                  display: 'flex',
                  margin: '0px 32px',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowBoxData(true)
                      setHiddenCancelData(false)
                    }}
                    variant="contained"
                    style={{
                      backgroundColor: colors.buttonErrorColor,
                      color: colors.textAccentColor,
                    }}
                  >
                    Cancelar Agendamento
                  </Button>
                  <Button
                    disabled={
                      !unitySelected ||
                      !selectedDate ||
                      !scheduleSelected ||
                      typeof scheduleService === 'string' ||
                      typeof scheduleService === 'undefined' ||
                      checkedDependente
                        ? !checkedDependente ||
                          !nameDependente ||
                          !cpfDependente ||
                          !birthDateDependente ||
                          !dependenteSelected
                        : false
                    }
                    color="primary"
                    id="confirm-agendamento"
                    variant="contained"
                    onClick={() => {
                      handleClose()
                      history.push('/')
                    }}
                  >
                    Finalizar
                  </Button>
                </Box>
              </DialogActions>
            )}
          </div>
          <div
            className={!hiddenInfoShedule && classes.concludedContent}
            hidden={hiddenInfoShedule}
          >
            <div hidden={hiddenWaring}>
              <DialogContent style={{ textAlign: 'center' }}>
                <Box>
                  <CheckCircleIcon
                    className={classes.iconLarge}
                    style={{ color: '#3CD278' }}
                  />
                </Box>
                <Typography className={classes.textSchedule}>
                  Agendamento concluído, enviamos para você um comprovante,
                  verifique no seu email.
                </Typography>
                <Typography className={classes.textSubtitleSchedule}>
                  Não esqueça de levar os documentos solicitados no ato do
                  agendamento.
                </Typography>
                <Typography className={classes.textSubtitleSchedule}>
                  Caso não possa comparecer, realizar o cancelamento do
                  agendamento no portal até 1h antes do horário previsto do
                  atendimento.
                </Typography>
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    className={classes.textSubtitleScheduleBox}
                    style={{ fontWeight: 600, marginTop: 30 }}
                  >
                    {typeof selectedDate?.getMonth === 'function' &&
                      format(selectedDate, 'dd/MM/yyyy')}{' '}
                    às {scheduleSelected}
                  </Typography>
                </Box>
              </DialogContent>
            </div>

            <SchedulingAssessment
              serviceSelected={serviceSelected}
              evaluationCompleted={evaluationCompleted}
              setEvaluationCompleted={setEvaluationCompleted}
              onPermittedCloseModal={handleHasScheduler}
              setHiddenWarning={setHiddenWaring}
              tipoAvaliacao="Agendamento"
            />
            <div hidden={evaluationCompleted}>
              <DialogActions
                style={{
                  margin: '10px 0px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Button
                  id="agendamentos-button"
                  className={classes.buttonPrimary}
                  onClick={(): void =>
                    history.push('/workspace/minhas_solicitacoes')
                  }
                >
                  <Typography className={classes.textButtonPrimary}>
                    Meus agendamentos
                  </Typography>
                </Button>
                <Button
                  id="inico-button"
                  onClick={handleClose}
                  className={classes.buttonOutlined}
                >
                  <Typography className={classes.textButtonOutlined}>
                    Voltar para o início
                  </Typography>
                </Button>
              </DialogActions>
            </div>
          </div>
          <div
            hidden={hiddenCancelData}
            className={classes.boxCancel}
            style={{
              display: !hiddenCancelData ? 'flex' : 'none',
            }}
          >
            {cancelSchedule ? (
              <DialogContent style={{ textAlign: 'center', marginTop: 50 }}>
                <Box>
                  <CancelIcon
                    style={{
                      color: colors.buttonErrorColor,
                      width: 80,
                      height: 80,
                      fontSize: 24,
                    }}
                  />
                </Box>
                <Typography className={classes.textSchedule}>
                  Agendamento cancelado com sucesso!
                </Typography>
                <Typography className={classes.textSubtitleSchedule}>
                  Seu atendimento presencial marcado para o dia{' '}
                  {typeof selectedDate?.getMonth === 'function' &&
                    format(selectedDate, 'dd/MM/yyyy')}{' '}
                  às {scheduleSelected} foi cancelado.
                </Typography>
                <Button
                  onClick={() => {
                    // handleClearHistoryInfo();
                    history.push('/')
                  }}
                  style={{
                    marginTop: 40,
                  }}
                  className={classes.buttonOutlined}
                >
                  <Typography className={classes.textButtonOutlined}>
                    Voltar para o início
                  </Typography>
                </Button>
              </DialogContent>
            ) : (
              <>
                {savedSchedule && (
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      gridGap="20px"
                      marginTop="20px"
                    >
                      <CancelIcon
                        style={{
                          color: colors.buttonErrorColor,
                          width: 80,
                          height: 80,
                          fontSize: 24,
                        }}
                      />
                      <Typography className={classes.titleCancel}>
                        Deseja cancelar seu atendimento?
                      </Typography>
                    </Box>
                    <DialogContent style={{ textAlign: 'left' }}>
                      {serviceSelected?.servicosUnidade.map(({ unidade }) => {
                        if (unidade.slug_unidade === unitySelected) {
                          return (
                            <Typography
                              key={unidade.slug_unidade}
                              className={classes.textUnidade}
                            >
                              {unidade.nome}
                            </Typography>
                          )
                        }
                      })}

                      <Typography className={classes.textService}>
                        {`Serviço: ${serviceSelected?.titulo}`}
                      </Typography>

                      <Typography className={classes.textOrgan}>
                        {`Órgão: ${serviceSelected.orgao_nome}`}
                      </Typography>

                      <Typography className={classes.textDateTime}>
                        Data da solicitação:
                        <Typography component="strong">
                          {' '}
                          {format(new Date(savedSchedule.data), 'dd/MM/yyyy')}
                        </Typography>
                      </Typography>

                      <Typography className={classes.textDateTime}>
                        Horário da solicitação:
                        <Typography component="strong">
                          {' '}
                          {savedSchedule.hora}
                        </Typography>
                      </Typography>
                    </DialogContent>
                    {loadingCancel ? (
                      <Box style={{ margin: '30px 0px', textAlign: 'center' }}>
                        <CircularProgress color="primary" />
                      </Box>
                    ) : (
                      <DialogActions
                        classes={{
                          root: classes.boxActionsCancel,
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleCancelAttendance(savedSchedule.id)
                          }
                          style={{
                            backgroundColor: colors.buttonErrorColor,
                            color: colors.textAccentColor,
                          }}
                        >
                          Sim, eu quero cancelar
                        </Button>
                        <Button
                          onClick={() => {
                            setHiddenCancelData(true)
                            setShowBoxData(false)
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Não, eu não quero cancelar
                        </Button>
                      </DialogActions>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Box>
      )}
    </>
  )
}
