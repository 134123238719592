/* eslint-disable no-console */
/* eslint-disable default-case */
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'

import {
  AttachDocument,
  registerIdentifiedEsic,
} from '../../../../services/esic'
import { formatBytes } from '../../../../utils/formatBytes'
import getCookie from '../../../../utils/getCookies'
import { EsicData } from '../..'

interface ConclusionProps {
  controlStep: (action: 'prev' | 'next') => void
  changeEsicCodes: (code: string, protocol: string) => void
  esicData: EsicData
}

export default function Conclusion({
  controlStep,
  esicData,
  changeEsicCodes,
}: ConclusionProps): JSX.Element {
  const useStyles = makeStyles((theme: Theme) => ({
    boxStepContent: {
      padding: '0px 10px',
      marginTop: 48,
    },
    actionTitle: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 500,
      textAlign: 'center',
      color: '#333',
      marginBottom: 48,
      [theme.breakpoints.down(660)]: {
        padding: '0px 16px',
        fontSize: theme.typography.pxToRem(18),
      },
    },
    ticket: {
      minHeight: 341,
      backgroundImage: 'url(/assets/images/ticket.png)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      marginBottom: 56,
      padding: '0px 10px',
      [theme.breakpoints.down(600)]: {
        backgroundImage: 'url(/assets/images/ticket-mobile.png)',
        padding: '0px 16px',
      },
    },
    grid: {
      padding: '40px 80px',
      [theme.breakpoints.down(721)]: {
        padding: '20px 40px',
      },
      [theme.breakpoints.down(600)]: {
        padding: '56px 47px 64px 47px',
      },
    },
    informationTitle: {
      fontSize: theme.typography.pxToRem(14),
      color: '#333',
      fontWeight: 700,
      display: 'block',
      marginBottom: 7,
      [theme.breakpoints.down(600)]: {
        textAlign: 'center',
      },
    },
    description: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: '#333',
      display: 'block',
      wordBreak: 'break-word',
      [theme.breakpoints.down(600)]: {
        textAlign: 'center',
      },
    },
    EsicDescription: {
      maxHeight: 110,
      overflow: 'hidden',
      whiteSpace: 'pre-line',
    },
    boxButton: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 24,
      gap: 16,
      paddingBottom: '56px',
    },
    button: {
      padding: '12px 43px',
      border: 'none',
      borderRadius: 4,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(16),
      cursor: 'pointer',
      fontWeight: 500,
      [theme.breakpoints.down(660)]: {
        width: '100%',
        padding: '12px 0px',
      },
    },
    forwardButton: {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 11,
      padding: ' 8px, 22px, 8px, 16px',
      border: 'none',
      backgroundColor: '#fff',
      color: '#333',
      [theme.breakpoints.down(660)]: {
        justifyContent: 'center',
      },
    },
    icon: {
      width: 18,
      height: 18,
      color: '#333',
    },
    moreContent: {
      display: 'block',
      textAlign: 'left',
      [theme.breakpoints.down(600)]: {
        textAlign: 'center',
      },
    },
  }))
  const token: string | null = getCookie('gov_access_token_sso')
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const matches = useMediaQuery('(max-width: 600px)')

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const { data } = await registerIdentifiedEsic(
        token,
        esicData?.conteudo,
        esicData?.tipo_identificacao,
        esicData?.canal_entrada,
        esicData?.assunto?.id,
        esicData?.tipo_demanda,
        esicData?.aceite,
        esicData?.complemento,
        esicData?.protocolo_relacionado,
      )
      if (data && esicData?.arquivos.length >= 1) {
        try {
          // eslint-disable-next-line no-restricted-syntax
          for (const fileUpload of esicData?.arquivos) {
            AttachDocument(data.id, fileUpload?.file?.name, fileUpload?.file)
          }
        } catch (err) {
          console.log(err)
        }
      }
      changeEsicCodes(esicData?.codigo_anonimo, data.protocolo)
      setLoading(false)
      controlStep('next')
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <Box className={classes.boxStepContent}>
      <Typography className={classes.actionTitle}>
        Confira todas as informações preenchidas antes de enviar
      </Typography>
      <Box className={classes.ticket}>
        <Grid container spacing={matches ? 3 : 6} className={classes.grid}>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.informationTitle}>
              Tipo de Identificação
            </Typography>
            <span className={classes.description}>
              {esicData?.tipo_identificacao}
            </span>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.informationTitle}>
              Canal de entrada
            </Typography>
            <span className={classes.description}>
              {esicData?.canal_entrada}
            </span>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.informationTitle}>
              Assunto
            </Typography>
            <span className={classes.description}>
              {esicData?.assunto?.titulo}
            </span>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.informationTitle}>
              Tipo de Demanda
            </Typography>
            <span className={classes.description}>
              {esicData?.tipo_demanda}
            </span>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.informationTitle}>
              Descrição da Demanda
            </Typography>
            <span className={classes.description}>{esicData?.conteudo}</span>
          </Grid>
          {esicData?.tipo_demanda !== 'Registro Inicial' && (
            <Grid item xs={12} sm={4}>
              <Typography className={classes.informationTitle}>
                {esicData?.tipo_demanda}
              </Typography>
              <span className={classes.description}>
                {esicData?.complemento}
              </span>
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <Typography className={classes.informationTitle}>Anexos</Typography>
            {esicData?.arquivos?.length > 0 ? (
              <>
                {esicData?.arquivos?.map((file) => (
                  <span
                    className={classes.description}
                    style={{ marginBottom: 10 }}
                    key={file?.file?.name}
                  >
                    {file?.file?.name}-{formatBytes(file.file?.size)}
                  </span>
                ))}
              </>
            ) : (
              <span className={classes.description}>
                Nenhum arquivo anexado
              </span>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.boxButton}>
        <button
          type="button"
          onClick={() => controlStep('prev')}
          className={`${classes.button} ${classes.backButton}`}
          disabled={loading}
        >
          <ArrowBackIcon className={classes.icon} />
          Voltar
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          className={`${classes.button} ${classes.forwardButton}`}
        >
          {loading ? (
            <CircularProgress
              style={{ width: 15, height: 15, color: '#fff' }}
            />
          ) : (
            'Confirmar'
          )}
        </button>
      </Box>
    </Box>
  )
}
