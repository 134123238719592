import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'
import React, { ReactNode } from 'react'

interface IdentificationRootProps {
  children: ReactNode
}

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export default function IdentificationRoot({
  children,
}: IdentificationRootProps): JSX.Element {
  const classes = useStyles()
  return <Box className={classes.container}>{children}</Box>
}
