import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { ClassNameMap } from '@material-ui/styles'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { workspaceMenuItems } from './data'

type WorkspaceMenuItemsProps = {
  classes: ClassNameMap
}

export default function WorkspaceMenuItems({
  classes,
}: WorkspaceMenuItemsProps) {
  return (
    <Fragment>
      {workspaceMenuItems.map((menuItem) => (
        <Link
          key={`key-${menuItem.name}`}
          to={menuItem.url}
          style={{ textDecoration: 'none' }}
        >
          <MenuItem className={classes.menuItem}>
            <menuItem.icon className={classes.iconMenuWorkspace} />
            <Typography className={classes.textMenuItem}>
              {menuItem.name}
            </Typography>
          </MenuItem>
        </Link>
      ))}
    </Fragment>
  )
}
