import { makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useContext } from 'react'

import ColorContext from '../../../../Context/colorContext'

export default function useSubHeaderStyles() {
  const { colors } = useContext(ColorContext)
  const isLargeScreen = useMediaQuery('(min-width:1250px)')
  const isSmallScreen = useMediaQuery('(min-width:660px)')
  const useStyles = makeStyles((theme: Theme) => ({
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 20,
      paddingBottom: 20,
      backgroundColor: 'white',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    container: {
      width: '100%',
      maxWidth: '1320px',
      padding: '0 10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    containerLeftSide: {
      gap: 10,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },
    containerRightSide: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },
    textCpf: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      textAlign: 'start',
      fontFamily: 'Roboto',
      color: theme.palette.primary.main,
      marginLeft: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(12),
        marginLeft: 0,
      },
    },
    buttonEnter: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonNotLogged: {
      padding: '0.5rem 2.5rem',
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      maxWidth: 108,
      height: 46,
      transition: 'opacity 0.3s ease',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        opacity: '0.8',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 94,
        height: 40,
      },
    },
    textName: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 'bold',
      textTransform: 'capitalize',
      color: theme.palette.primary.main,
      marginLeft: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
        marginLeft: 0,
      },
    },
    textButton: {
      fontWeight: 700,
      fontStyle: 'normal',
      marginLeft: 3,
      fontSize: 14,
      color: colors.textAccentColor,
      textTransform: 'uppercase',
    },
    containerAvatar: {
      borderRadius: 0,
      height: 48,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    avatar: {
      height: 48,
      width: 48,
      border: `2px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.down('sm')]: {
        width: 40,
        height: 40,
      },
    },
    userPanel: {
      width: '100%',
      maxWidth: 134,
      padding: '13px 25px',
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(14),
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,
      fontWeight: 700,
      height: 46,

      transition: 'opacity 0.3s ease',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        opacity: '0.8',
      },
    },
    logo: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      height: 'auto',
    },
    iconDrawer: {
      fontSize: theme.typography.pxToRem(50),
      color: theme.palette.primary.main,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(32),
      },
    },
  }))
  const classes = useStyles()

  return {
    classes,
    colors,
    isLargeScreen,
    isSmallScreen,
  }
}
