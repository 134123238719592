import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import React from 'react'

import { SearchI } from '../../../../interfaces/service'
import useStyles from './styles'

interface ContainerInfoActionProps {
  serviceSelected: SearchI
  serviceHasSaved: boolean
  handleRemoveServiceSelected: () => void
}

export default function ContainerInfoAction({
  serviceSelected,
  serviceHasSaved,
  handleRemoveServiceSelected,
}: ContainerInfoActionProps): JSX.Element {
  const classes = useStyles()
  return (
    <Box>
      <Typography className={classes.serviceSelectedTextTitle}>
        {serviceSelected?.categoria_nome}
      </Typography>
      <Box className={classes.boxNextOptions}>
        <Box>
          <Typography className={classes.serviceSelectedText}>
            {serviceSelected?.titulo}
          </Typography>
          <Typography className={classes.serviceSelectedSubText}>
            {serviceSelected?.orgao_nome}
          </Typography>
        </Box>
        {!serviceHasSaved && (
          <IconButton id="trash" onClick={handleRemoveServiceSelected}>
            <DeleteOutlineIcon className={classes.iconTrash} />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}
