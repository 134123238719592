/* eslint-disable jsx-a11y/click-events-have-key-events */
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import React, { useContext, useRef } from 'react'
import Slider, { CustomArrowProps, Settings } from 'react-slick'

import colorContext from '../../../../Context/colorContext'
import { ChannelAttendances } from '../../../Home/types/get.channelAttendances'

interface Props {
  listChannel: ChannelAttendances[]
}

export default function ChannelAttendante({ listChannel }: Props): JSX.Element {
  const { colors } = useContext(colorContext)
  const matchesMobile = useMediaQuery('(max-width:660px)')
  const matches = useMediaQuery('(max-width:1250px)')
  const showMore = false
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#F5F8FA',
      alignItems: 'center',
      padding: '54px 10px 81px 10px',
      [theme.breakpoints.down(660)]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    boxChannel: {
      display: 'flex',
      maxWidth: '1320px',
      width: '100%',
      flexDirection: 'column',
      gap: 50,
    },
    channelHeader: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: matches ? 'column' : 'row',
      gap: matches ? 20 : 32,
    },
    channelList: {
      display: !showMore ? 'flex !important' : '',
      justifyContent: !matchesMobile ? 'space-between' : 'space-evenly',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    card: {
      marginLeft: 5,
      marginRight: 5,
      marginTop: 10,
      height: matchesMobile ? 144 : 180,
      maxWidth: matchesMobile ? 144 : 180,
      width: '100%',
      borderRadius: '50%',
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(18),
      backgroundColor: colors.accentColorBackground,
    },
    textTitle: {
      fontSize: matchesMobile ? 20 : 24,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      fontWeight: 700,
      color: colors.accentColor,
      display: 'flex',
      alignItems: 'center',
      flexDirection: matches ? 'column' : 'row',
      gap: 16,
    },
    IconNavite: {
      color: '#fff',
      width: 32,
      height: 32,
      background: colors.accentColorBackground,
      padding: 0,
      '&:hover': {
        backgroundColor: colors.cardAccentColor,
        color: '#fff',
      },
    },
    icon: {
      width: '100%',
      height: '100%',
    },
    slider: {
      '& .slick-list': {
        margin: 0,
      },
      '& .slick-slide': {
        padding: 0,
      },
    },
  }))
  const classes = useStyles()

  const sliderRef = useRef<Slider | null>(null)

  const NextArrow = ({ onClick, style }: CustomArrowProps): JSX.Element => (
    <IconButton
      className={classes.IconNavite}
      onClick={() => sliderRef.current.slickNext()}
    >
      <NavigateNextIcon className={classes.icon} />
    </IconButton>
  )

  const PrevArrow = ({ onClick, style }: CustomArrowProps): JSX.Element => (
    <IconButton
      className={classes.IconNavite}
      onClick={() => sliderRef.current.slickPrev()}
    >
      <NavigateBeforeIcon className={classes.icon} />
    </IconButton>
  )

  const settings: Settings = {
    speed: 500,
    infinite: true,
    slidesToShow: listChannel.length >= 6 ? 6 : listChannel.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }
  return (
    <Box id="atendimentos" className={classes.main}>
      <Box className={classes.boxChannel}>
        <Box className={classes.channelHeader}>
          <Typography className={classes.textTitle} variant="h5">
            <HeadsetMicIcon fontSize={matchesMobile ? 'default' : 'large'} />
            Canais de manifestação
          </Typography>
          <Box display="flex" alignItems="center" style={{ gap: 8 }}>
            <PrevArrow />
            <NextArrow />
          </Box>
        </Box>
        <Box>
          {showMore ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
            >
              {listChannel.map((chan) => (
                <Box key={`${chan.titulo}-key`} className={classes.channelList}>
                  {[...listChannel].map((channel) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      style={{
                        maxWidth: matchesMobile ? 144 : 180,
                        width: '100%',
                        height: 'auto',
                      }}
                      key={`key-${channel.titulo}`}
                    >
                      <Card key={channel.titulo} className={classes.card}>
                        <CardActionArea
                          style={{
                            height: '100%',
                          }}
                        >
                          <CardContent
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <span
                              style={{
                                fontSize: matchesMobile ? 70 : 80,
                                color: '#fff',
                              }}
                              className="material-icons"
                            >
                              {channel.icone}
                            </span>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                      <Typography
                        align="center"
                        variant="h5"
                        style={{
                          color: colors.accentColor,
                          fontSize: matchesMobile ? 18 : 22,
                          marginTop: 25,
                          fontWeight: 700,
                          textTransform: 'uppercase',
                        }}
                      >
                        {channel.titulo}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          ) : (
            <Box style={{ overflowX: 'hidden' }}>
              <Slider {...settings} ref={sliderRef} className={classes.slider}>
                {[...listChannel].map((channel) => (
                  <Box
                    display={!showMore ? 'flex !important' : ''}
                    alignItems="center"
                    flexDirection="column"
                    style={{
                      maxWidth: matchesMobile ? 144 : 180,
                      width: '100%',
                      height: 'auto',
                    }}
                    key={`key-${channel.titulo}-t`}
                  >
                    <Card key={channel.titulo} className={classes.card}>
                      <CardActionArea
                        style={{
                          height: '100%',
                        }}
                      >
                        <CardContent
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <span
                            style={{
                              fontSize: matchesMobile ? 70 : 80,
                              color: '#fff',
                            }}
                            className="material-icons"
                          >
                            {channel.icone}
                          </span>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                    <Typography
                      align="center"
                      variant="h5"
                      style={{
                        color: colors.accentColor,
                        fontSize: matchesMobile ? 18 : 22,
                        marginTop: 25,
                        fontWeight: 700,
                        textTransform: 'uppercase',
                      }}
                    >
                      {channel.titulo}
                    </Typography>
                  </Box>
                ))}
              </Slider>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
