/* eslint-disable @typescript-eslint/ban-types */
import { Editor } from '@tinymce/tinymce-react'
import React from 'react'
// import { sendFile } from '../../services/form-flow';

type Props = {
  id?: string
  value?: string
  setValue?: (value: string) => void
  onChange?: (value: string) => void
  modeEdit?: 'basic' | 'full'
  addPlugins?: string[]
  addConf?: object
  addToolbar?: string[]
  disabled?: boolean
  print?: boolean
}

const MyEditor = (props: Props) => {
  // const imageUploadHandle = async (blobInfo, success, failure) => {
  //     try {
  //         const { data } = await sendFile(blobInfo.blob());
  //         if (data) {
  //             success(data.arquivo || '');
  //         }
  //     } catch (error) {
  //         console.log(error);
  //         failure('error');
  //     }
  // }

  const getPlugins = (modeEdit: 'basic' | 'full') => {
    const plugins = []
    if (props.print) {
      plugins.push('print')
    }
    // if (modeEdit === 'full') {
    //   plugins.push('link', 'image', 'table');
    // }
    return plugins
  }

  return (
    <Editor
      tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
      value={props.value}
      id={props.id}
      onEditorChange={(content) => {
        props.setValue && props.setValue(content)
      }}
      init={{
        plugins: [
          'image',
          'accordion',
          'anchor',
          'media',
          'table',
          'link',
          'autolink',
          'autoresize',
          'charmap',
          'code',
          'codesample',
          'directionality',
          'emoticons',
          'fullscreen',
          'help',
          'importcss',
          'insertdatetime',
          'link',
          'lists',
          'nonbreaking',
          'pagebreak',
          'preview',
          'quickbars',
          'searchreplace',
          'visualblocks',
          'visualchars',
          'wordcount',
        ],
        // images_upload_handler: imageUploadHandle,
        toolbar: props.print
          ? ['print']
          : 'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | print | image | table | link  | wordcount',
        menubar: false,
        ...props.addConf,
        ...props.addToolbar,
      }}
      disabled={props.disabled}
    />
  )
}

export default MyEditor
