import {
  TbAccessible,
  TbBook2,
  TbBuildingBank,
  TbInfoCircleFilled,
  TbMessageCircle,
  TbScale,
  TbSearch,
} from 'react-icons/tb'

export const menuItems = [
  {
    titulo: 'Transparência',
    external_url: process.env.REACT_APP_LINK_ACESSO_TRANSPARENCIA,
    icon: TbSearch,
    isAuth: false,
  },
  {
    titulo: 'Legislação',
    external_url: process.env.REACT_APP_LINK_LEGISLACAO,
    icon: TbScale,
    isAuth: false,
  },
  {
    titulo: 'Estrutura da Prefeitura',
    url: '/orgaos',
    icon: TbBuildingBank,
    isAuth: false,
  },
  {
    titulo: 'Acessibilidade',
    url: '/acessibilidade',
    icon: TbAccessible,
    isAuth: false,
  },
  {
    titulo: 'Diário Oficial do Município',
    external_url: process.env.REACT_APP_LINK_DIARIO,
    icon: TbBook2,
    isAuth: false,
  },
  {
    titulo: 'Fale com a Ouvidoria',
    url: '/ouvidoria',
    icon: TbMessageCircle,
    isAuth: false,
  },
  {
    titulo: 'Acesso à informação',
    external_url: process.env.REACT_APP_LINK_ACESSO_INFORMACAO,
    url: '/esic',
    icon: TbInfoCircleFilled,
    isAuth: false,
  },
]

export type MenuItems = typeof menuItems
