import DateFnsUtils from '@date-io/date-fns'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import ptBR from 'date-fns/locale/pt-BR'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import colorContext from '../../Context/colorContext'
import {
  checarHorariosReagendamento,
  checarReagendamento,
  reagendar,
} from '../../services/reagendamento'
import InfoMessage from './InfoMessage'
import InformationBox from './InformationBox'

interface ParamsProps {
  url: string
}

interface ReagendamentoHorariosProps {
  dia_semana: string
  horarios: []
  message?: string
}

interface ReagendamentoProps {
  agendamento: {
    data: string
    hora: string
  }
  identificador: string
  servico: {
    id: number
    titulo: string
  }
  unidade: {
    id: number
    nome: string
  }
  user: {
    name: string
  }
}

export default function Reschedule(): JSX.Element {
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: '#d3d3d3',
        },
      },
    },
    adornedEnd: {
      paddingRight: 0,
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    containerIconLoading: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    main: {
      margin: '50px 0px',
      maxWidth: 1040,
      width: '100%',
      minHeight: 660,
      backgroundColor: colors.colorBackground,
      border: '1px solid #E1E6EF',
      borderRadius: 8,
    },
    boxIcon: {
      margin: '40px 0px 36px 0px',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    iconStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 24,
      width: 120,
      height: 120,
    },
    boxWelcome: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    welcomeText: {
      color: '#333333',
      fontFamily: 'Roboto',
      fontSize: 24,
      fontWeight: 700,
      marginBottom: 20,
    },

    forms: {
      margin: '56px 0px 72px 0px',
      width: '100%',
      minHeight: 280,
      display: 'flex',
      justifyContent: 'center',
    },
    formsInput: {
      width: 512,
      border: '1px solid #E1E6EF',
      borderRadius: 4,
      padding: '32px 32px 40px 32px',
      margin: '0px 20px',
    },
    formControl: {
      marginTop: 20,
      marginBottom: 20,
      width: '100%',
    },
    menuItem: {
      color: theme.palette.primary.main,
    },
    boxConfirmRescheduling: {
      width: '100%',
      minHeight: 48,
    },
    buttonConfirmRescheduling: {
      width: '100%',
      height: 48,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 700,
      borderRadius: 4,
      textTransform: 'uppercase',
      backgroundColor: colors.accentColorBackground,
      color: colors.textAccentColor,
      '&:hover': {
        backgroundColor: colors.accentColorBackground,
      },
    },
    buttonConfirmReschedulingDisabled: {
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 700,
      borderRadius: 4,
      textTransform: 'uppercase',
      width: '100%',
      height: 48,
      backgroundColor: '#E7E9ED',
      color: '#9EA5B8',
    },
  }))
  const classes = useStyles()
  const { url } = useParams<ParamsProps>()
  const [reschedule, setReschedule] = useState<ReagendamentoProps | undefined>()
  const [selectedDate, handleDateChange] = useState<Date>(new Date())
  const [scheduleSelected, setScheduleSelected] = useState<string>('')
  const [scheduleHourList, setScheduleHourList] = useState<
    ReagendamentoHorariosProps | string
  >()
  const [loading, setLoading] = useState<boolean>(false)
  const [rescheduleSaved, setRescheduleSaved] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const disableWeekends = (date: MaterialUiPickersDate): boolean =>
    date
      ? date.getDay() === 0 ||
        date.getDay() === 6 ||
        format(date, 'yyyy-MM-dd') === '2021-02-16'
      : false

  useEffect(() => {
    const getReagendamento = async () => {
      setLoading(true)
      try {
        const { data } = await checarReagendamento(url)

        setReschedule(data)
      } catch (err) {
        // eslint-disable-next-line no-console
        setErrorMessage(err.response.data.message)
      } finally {
        setLoading(false)
      }
    }

    getReagendamento()
  }, [url])

  const handleReagendamento = async () => {
    try {
      setLoading(true)
      const response = await reagendar(selectedDate, scheduleSelected, url)

      if (response.status === 201) {
        setRescheduleSaved(true)
        setReschedule(undefined)
      } else {
        setRescheduleSaved(false)
      }
    } catch (error) {
      setErrorMessage(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  async function handleHourCheck(date: Date) {
    try {
      const { data } = await checarHorariosReagendamento(url, date)

      setScheduleHourList(data)
    } catch (err) {
      if (err.response.data.message) {
        setScheduleHourList(err.response.data.message)
      } else {
        setScheduleHourList('Algo inesperado aconteceu!')
      }
    }
  }

  useEffect(() => {
    handleHourCheck(new Date())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container className={classes.container}>
      {loading ? (
        <div className={classes.containerIconLoading}>
          <CircularProgress style={{ width: 50, height: 50 }} />
        </div>
      ) : (
        <>
          {reschedule && (
            <>
              <Box className={classes.main}>
                <Box className={classes.boxIcon}>
                  <Box className={classes.iconStyle}>
                    <img
                      src={process.env.REACT_APP_LOGO_REAGENDAMENTO}
                      width="100%"
                      height="auto"
                      alt="logo"
                    />
                  </Box>
                </Box>
                <Box className={classes.boxWelcome}>
                  <Typography
                    className={classes.welcomeText}
                  >{`Olá, ${reschedule.user.name}`}</Typography>
                </Box>
                <InformationBox
                  unity={reschedule.unidade.nome}
                  service={reschedule.servico.titulo}
                />
                <Box className={classes.forms}>
                  <Box className={classes.formsInput}>
                    <div id="input-data">
                      <FormControl
                        classes={{ root: classes.root }}
                        error={
                          typeof scheduleHourList === 'string' ||
                          typeof errorMessage === 'string'
                        }
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <MuiPickersUtilsProvider
                          locale={ptBR}
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            disableFuture={false}
                            error={
                              typeof scheduleHourList === 'string' ||
                              typeof errorMessage === 'string'
                            }
                            shouldDisableDate={disableWeekends}
                            minDateMessage="Você não pode agendar em dias anteriores"
                            invalidDateMessage="A data informada é inválida"
                            minDate={new Date()}
                            InputProps={{
                              className: classes.menuItem,
                              classes: { adornedEnd: classes.adornedEnd },
                            }}
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Escolha o dia"
                            format="dd/MM/yyyy"
                            value={selectedDate}
                            InputAdornmentProps={{
                              position: 'end',
                              color: '#000',
                            }}
                            onChange={(date) => {
                              if (date && isValid(date)) {
                                handleDateChange(date)
                                handleHourCheck(date)
                                setErrorMessage(null)
                              }
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        {typeof scheduleHourList === 'string' && (
                          <FormHelperText>{scheduleHourList}</FormHelperText>
                        )}
                        {typeof errorMessage === 'string' && (
                          <FormHelperText>{errorMessage}</FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div id="input-horario">
                      <FormControl
                        classes={{ root: classes.root }}
                        disabled={!selectedDate}
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Escolha o horário
                        </InputLabel>
                        <Select
                          defaultValue=""
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          classes={{
                            select: classes.menuItem,
                          }}
                          onChange={(event): void => {
                            setScheduleSelected(event.target.value as string)
                          }}
                          label="Escolha o horário"
                        >
                          {typeof scheduleHourList !== 'string' &&
                            scheduleHourList &&
                            scheduleHourList?.horarios?.length > 0 &&
                            scheduleHourList?.horarios?.map((schedule) => (
                              <MenuItem
                                key={schedule}
                                className={classes.menuItem}
                                value={schedule}
                              >
                                {schedule}
                              </MenuItem>
                            ))}
                        </Select>
                        {typeof scheduleHourList === 'string' && (
                          <FormHelperText>{scheduleHourList}</FormHelperText>
                        )}
                      </FormControl>
                    </div>

                    <Box className={classes.boxConfirmRescheduling}>
                      <Button
                        className={
                          !selectedDate || !scheduleSelected
                            ? classes.buttonConfirmReschedulingDisabled
                            : classes.buttonConfirmRescheduling
                        }
                        disabled={!selectedDate || !scheduleSelected}
                        id="confirm-reagendamento"
                        onClick={handleReagendamento}
                      >
                        {loading ? (
                          <CircularProgress
                            style={{ width: 25, height: 25 }}
                            color="secondary"
                          />
                        ) : (
                          'reagendar'
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </>
      )}

      {!reschedule &&
        !loading &&
        typeof errorMessage === 'string' &&
        !rescheduleSaved && (
          <InfoMessage typeError errorMessage={errorMessage} />
        )}

      {!loading && reschedule === undefined && rescheduleSaved && (
        <InfoMessage typeError={false} />
      )}
    </Container>
  )
}
