import './index.css'

import {
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles/'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import Tags from './Components/MetaTags'
import theme from './services/theme-service'
import * as serviceWorker from './serviceWorker'

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope)
      })
      .catch((error) => {
        console.log('Service Worker registration failed:', error)
      })
  })
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'portal',
  disableGlobal: true,
})

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <Tags />
        <App />
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

serviceWorker.register()
