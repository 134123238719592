import React from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

import getCookie from '../../utils/getCookies'

interface Props extends RouteProps {
  layout: React.ComponentType<any>
  component: React.ComponentType<any>
  // eslint-disable-next-line react/require-default-props
  isAuth?: boolean
}

const RouteLayout = (props: Props): JSX.Element => {
  const token: string | null = getCookie('gov_access_token_sso')
  const { pathname } = useLocation()

  const { layout: Layout, component: Component, path, isAuth } = props

  const attendanceOnlineChatUrl = '/workspace/atendimento_online/'
  const doesNotPathNameHasAttendanceOnlineChatUrl = !pathname.startsWith(
    attendanceOnlineChatUrl,
  )

  if (isAuth && !token && doesNotPathNameHasAttendanceOnlineChatUrl) {
    return <Redirect to="/" />
  }

  return (
    <Route
      path={path}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  )
}

export default RouteLayout
