/* eslint-disable react/require-default-props */
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

interface IdentificationActionButtonProps {
  handleSubmit: (value?: 'next' | 'prev') => void
  variant: 'contained' | 'outlined' | 'text'
  isDisabled: boolean
  isVisible?: boolean
  text: string
}

const useStyles = makeStyles(() => ({
  buttonOutlined: {
    border: 'none',
    '&:hover': {
      border: 'none',
      backgroundColor: 'inherit',
    },
  },
}))

export default function IdentificationActionButton({
  handleSubmit,
  variant,
  isDisabled = false,
  isVisible = true,
  text,
}: IdentificationActionButtonProps): JSX.Element {
  const classes = useStyles()
  return (
    <>
      {isVisible && (
        <Button
          variant={variant}
          className={variant === 'outlined' && classes.buttonOutlined}
          color="primary"
          onClick={() => handleSubmit()}
          disabled={isDisabled}
        >
          {text}
        </Button>
      )}
    </>
  )
}
