/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable default-case */
import {
  faUserCheck,
  faUserLock,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Radio from '@material-ui/core/Radio'
import Theme from '@material-ui/core/styles/MuiThemeProvider'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import colorContext from '../../../../Context/colorContext'
import { loginRedirectPrivider } from '../../../../services/auth'
import {
  cpfMask,
  removeCpfMask,
  validateCpf,
} from '../../../../utils/cpfFormat'
import getCookie from '../../../../utils/getCookies'
import validateEmail from '../../../../utils/validateEmail'
import { DemandData, SlugManifestation } from '../..'

interface IdentificationProps {
  controlStep: (action: 'prev' | 'next') => void
  changeDemandData: (data: DemandData) => void
  slug: string
  demandaData: DemandData
}

export default function Identification({
  controlStep,
  changeDemandData,
  slug,
  demandaData,
}: IdentificationProps): JSX.Element {
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles((theme: Theme) => ({
    boxStepContent: {
      padding: '0px 8px',
      marginTop: 48,
    },
    actionTitle: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 500,
      textAlign: 'center',
      color: colors.textBody,
      [theme.breakpoints.down(660)]: {
        padding: '0px 10px',
        fontSize: theme.typography.pxToRem(18),
      },
    },
    boxCards: {
      marginTop: 48,
      display: 'flex',
      gap: 16,
      marginBottom: 80,
      [theme.breakpoints.down(660)]: {
        marginTop: 32,
        padding: '0px 10px',
        marginBottom: 50,
      },
    },
    card: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #DAE1EF',
      borderRadius: 4,
      padding: '32px',
      cursor: 'pointer',
      [theme.breakpoints.down(660)]: {
        padding: '22px 22px 32px 22px',
      },
    },
    cardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cardChecked: {
      borderColor: colors.accentColorBackground,
    },
    radioChecked: {
      color: colors.accentColorBackground,
    },
    radioInfo: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 16,
    },
    title: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 600,
      display: 'block',
      marginBottom: 11,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    description: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      display: 'block',
      color: '#4F5766',
      lineHeight: '24px',
      maxWidth: 690,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(14),
        maxWidth: '350px',
      },
    },
    icon: {
      width: 32,
      height: 32,
      color: '#DAE1EF',
      [theme.breakpoints.down(660)]: {
        width: 24,
        height: 24,
      },
    },
    iconChecked: {
      color: colors.accentColor,
    },
    boxButton: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 24,
      paddingBottom: '56px',
      [theme.breakpoints.down(500)]: {
        padding: '0px 10px 40px 10px',
        flexDirection: 'column',
        gap: 30,
      },
    },
    warning: {
      fontSize: theme.typography.pxToRem(14),
      color: '#4F5766',
      fontWeight: 400,
    },
    button: {
      padding: '12px 43px',
      border: 'none',
      borderRadius: 4,
      backgroundColor: colors.accentColorBackground,
      color: colors.textAccentColor,
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(16),
      cursor: 'pointer',
      fontFamily: 'Roboto',
      fontWeight: 500,
      [theme.breakpoints.down(500)]: {
        width: '100%',
      },
    },
    boxQuery: {
      maxWidth: 1320,
      width: '100%',
      margin: '40px auto 0px auto',
      paddingBottom: '56px',
      padding: '0px 10px',
    },
    queryTitle: {
      fontSize: theme.typography.pxToRem(24),
      color: colors.textBody,
      fontWeight: 700,
      marginBottom: 16,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: 'center',
      },
    },
    queryDescription: {
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBody,
      fontWeight: 500,

      '&:last-child': {
        marginTop: 10,
      },

      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(15),
        textAlign: 'center',
      },
    },
    queryLink: {
      fontWeight: 500,
      color: colors.textBody,
    },
    error: {
      color: colors.buttonErrorColor,
    },
    helperText: {
      marginTop: 5,
      marginLeft: 5,
    },
  }))
  const classes = useStyles()
  const location = useLocation()

  const userData = getCookie('gov_user_data') || ''
  const token: string | null = getCookie('gov_access_token_sso')
  const tokenSiseci: string | null = getCookie('gov_access_token_sso')

  const [user, setUser] = useState<'Identificado' | 'Anônimo' | 'Sigiloso'>(
    null,
  )
  const [code, setCode] = useState('')
  const [disabled, setDisabled] = useState(true)

  const controlRadio = (type: 'Identificado' | 'Anônimo' | 'Sigiloso') => {
    setUser(type)
  }

  const loginRedirect = () => {
    const { pathname } = location
    loginRedirectPrivider(pathname)
  }

  const confirmation = () => {
    switch (user) {
      case 'Identificado':
        if (userData && token) {
          changeDemandData({
            tipo_identificacao: 'Identificado',
            ...demandaData,
          })
          controlStep('next')
          break
        } else {
          loginRedirect()
          break
        }
      case 'Sigiloso':
        changeDemandData({
          tipo_identificacao: 'Sigiloso',
          ...demandaData,
        })
        controlStep('next')
        break
    }
  }

  const controlButton = () => {
    switch (user) {
      case 'Identificado':
        setDisabled(false)
        break
      case 'Sigiloso':
        setDisabled(false)
        break
    }
  }

  useEffect(() => {
    setCode('')
  }, [user])

  useEffect(() => {
    controlButton()
  }, [code, user])

  return (
    <Box className={classes.boxStepContent}>
      <Typography className={classes.actionTitle}>
        Qual tipo de identificação deseja utilizar?
      </Typography>
      <Grid container className={classes.boxCards}>
        <Grid item xs={12}>
          <Box
            className={`${
              user === 'Identificado'
                ? `${classes.card} ${classes.cardChecked}`
                : classes.card
            }`}
            onClick={() => controlRadio('Identificado')}
          >
            <Box className={classes.cardHeader}>
              <Box className={classes.radioInfo}>
                <FontAwesomeIcon
                  className={`${
                    user === 'Identificado'
                      ? `${classes.icon} ${classes.iconChecked}`
                      : classes.icon
                  }`}
                  icon={faUserCheck}
                />
                <Box>
                  <Typography className={classes.title}>
                    Identificado
                  </Typography>
                  <span className={classes.description}>
                    Utilizando o login único (solução própria do gov.br).
                  </span>
                </Box>
              </Box>
              <Radio
                color="default"
                checked={user === 'Identificado'}
                onChange={() => controlRadio('Identificado')}
                value="user"
                name="radio-button"
                classes={{ checked: classes.radioChecked }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            className={`${
              user === 'Sigiloso'
                ? `${classes.card} ${classes.cardChecked}`
                : classes.card
            }`}
            onClick={() => controlRadio('Sigiloso')}
          >
            <Box className={classes.cardHeader}>
              <Box className={classes.radioInfo}>
                <FontAwesomeIcon
                  className={`${
                    user === 'Sigiloso'
                      ? `${classes.icon} ${classes.iconChecked}`
                      : classes.icon
                  }`}
                  icon={faUserLock}
                />
                <Box>
                  <Typography className={classes.title}>Sigiloso</Typography>
                  <span className={classes.description}>
                    Utilizando o login único (solução própria do gov.br), mas
                    seus dados são criptografados.
                  </span>
                </Box>
              </Box>
              <Radio
                color="default"
                checked={user === 'Sigiloso'}
                onChange={() => controlRadio('Sigiloso')}
                value="Anônimo"
                name="radio-button"
                classes={{ checked: classes.radioChecked }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.boxButton}>
        <span className={classes.warning}>
          * Campo de preenchimento obrigatório
        </span>
        <button
          type="button"
          onClick={confirmation}
          className={classes.button}
          disabled={disabled}
          style={{
            opacity: disabled ? '0.7' : '1',
          }}
        >
          Avançar
        </button>
      </Box>
      {/* <Divider />
      <Box className={classes.boxQuery}>
        <Typography className={classes.queryTitle}>
          Para consultar sua manifestação
        </Typography>
        <Typography className={classes.queryDescription}>
          Vá em{" "}
          <Link to="/ouvidoria" className={classes.queryLink}>
            Consultar Manifestação
          </Link>
          , informe o Número de Protocolo e o Código de Acesso.
        </Typography>
        <Typography className={classes.queryDescription}>
          Para manifestações identificadas, acompanhe o andamento
          autenticando-se no Portal Único, na opção{" "}
          <Link to="/workspace/minha-area" className={classes.queryLink}>
            Minhas Manifestações.
          </Link>
        </Typography>
      </Box> */}
    </Box>
  )
}
