import { AxiosResponse } from 'axios'

import { apiouvidoria } from './api'

export const getListSubjectsEsic = (): Promise<AxiosResponse> =>
  apiouvidoria.get('assuntos-esic/')

export const getItemEsicData = (
  token: string,
  protocolo: string,
  tipo_demanda: string,
): Promise<AxiosResponse> =>
  apiouvidoria.get(
    `/esic-protocolo/?protocolo=${protocolo}&tipo_demanda=${tipo_demanda}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

export const registerIdentifiedEsic = (
  token: string,
  conteudo: string,
  tipo_identificacao: string,
  canal_entrada: string,
  assunto: number,
  tipo_demanda: string,
  aceite: number,
  complemento: string,
  protocolo_relacionado?: string,
): Promise<AxiosResponse> =>
  apiouvidoria.post(
    'esic-auth/',
    {
      descricao: conteudo,
      tipo_identificacao,
      canal_entrada,
      assunto,
      tipo_demanda,
      aceite,
      complemento,
      resumo: conteudo,
      protocolo_relacionado,
      tipo_tempo: 'Dias',
      tempo_total: 2,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

export const searchEsic = (numero: string): Promise<AxiosResponse> =>
  apiouvidoria.get('esic/', {
    params: {
      numero,
    },
  })

export const AttachDocument = (
  id: number | string,
  titulo: string,
  arquivo: File,
): Promise<AxiosResponse> => {
  const formData = new FormData()
  formData.append('esic', id as string)
  formData.append('titulo', titulo)
  formData.append('arquivo', arquivo)
  return apiouvidoria.post('anexos_esic/', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}
