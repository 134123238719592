import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import React, { useContext, useEffect, useState } from 'react'

import MyEditor from '../../Components/MyEditor'
import colorContext from '../../Context/colorContext'
import { validateDocument } from '../../services/servico'
import theme from '../../services/theme-service'
import dateFormat from '../../utils/dateFormat'

export default function DocumentValidation(props): JSX.Element {
  const { hash, id } = props.match.params
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: 500,
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      width: '100%',
      maxWidth: 100,
      height: 45,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },
    buttonClear: {
      textTransform: 'uppercase',
      width: '100%',
      height: 45,
      backgroundColor: 'green',
      borderRadius: 4,
      '&:hover': {
        backgroundColor: 'green',
        color: '#fff',
      },
      maxWidth: 500,
    },
    input: {
      color: 'black',
    },
    contentButtons: {
      display: 'flex',
      gap: 10,
      width: '100%',
    },
    textInfo: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      fontFamily: 'Roboto',
      color: '#856404',
      margin: '0px 10px',
    },
    boxInfo: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    boxIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: colors.accentColor,
      marginBottom: 16,
    },
    title: {
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontWeight: 700,
      color: colors.textBody,
    },
    boxWarning: {
      width: '100%',
      height: 56,
      backgroundColor: '#FFF3CD',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
    },
    iconWarning: {
      marginLeft: 10,
    },
  }))
  const [codVerification, setCodVerification] = useState<string>(id || '')
  const [hashVerification, setHashVerification] = useState<string>(hash || '')
  const [dataDocument, setDocument] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const classes = useStyles()

  const handleOnSubmit = async () => {
    if (!codVerification && !hashVerification) return

    setIsLoading(true)
    try {
      const { data } = await validateDocument(codVerification, hashVerification)
      const documento = data?.results?.[0] ? data.results[0] : undefined
      setDocument(documento)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleClear = () => {
    setDocument(null)
    setCodVerification('')
    setHashVerification('')
  }

  useEffect(() => {
    if (hash && id) {
      handleOnSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, id])

  const addInfoSign = () => `
    <br/>

    <div style="text-align: left; background: #F3F4F7; position: fixed; bottom: 0; width: 100%; margin-top: 10px; font-size: 11px">
        Autenticidade gerada automaticamente pelo sistema em ${
          dataDocument?.updated_at && dateFormat(dataDocument?.updated_at, true)
        }
        <br/>
        Hash de verificação:  ${dataDocument?.hash}
        <br/>
        Identificação:  ${dataDocument?.id}
        <br/>
        “Essa assinatura tem respaldo legal de acordo com a Lei Federal n° 14.129”<br />
        Consulte a autenticidade do documento em: ${
          process.env.REACT_APP_URL_CONSULTAR_DOC
        }
    </div>`

  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1052px"
        width="100%"
        alignItems="center"
        flexDirection="column"
        margin="80px 0px"
        padding="0px 10px"
        gridGap={20}
      >
        {!dataDocument && (
          <Box
            width="100%"
            maxWidth="500px"
            flexDirection="column"
            display="flex"
            gridGap={20}
          >
            <Box className={classes.boxInfo}>
              <Box className={classes.boxIcon}>
                <PlaylistAddCheckIcon
                  fontSize="default"
                  style={{ color: 'white' }}
                />
              </Box>
              <Typography className={classes.title}>
                Confirmação de Autenticidade do documento
              </Typography>
            </Box>
            <FormControl>
              <TextField
                label="Hash de verificação"
                variant="outlined"
                value={hashVerification}
                onChange={(e) => setHashVerification(e.target.value)}
                inputProps={{ className: classes.input }}
              />
            </FormControl>
            <FormControl>
              <TextField
                label="Código de Identificação"
                variant="outlined"
                value={codVerification}
                onChange={(e) => setCodVerification(e.target.value)}
                inputProps={{ className: classes.input }}
              />
            </FormControl>
            <div className={classes.contentButtons}>
              <Button
                onClick={handleOnSubmit}
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={!codVerification || !hashVerification}
              >
                Buscar
              </Button>
              {(dataDocument || dataDocument === undefined) && (
                <Button
                  onClick={handleClear}
                  className={classes.buttonClear}
                  variant="outlined"
                >
                  consultar outro documento
                </Button>
              )}
            </div>

            {isLoading && !dataDocument && (
              <CircularProgress
                style={{ width: 25, height: 25 }}
                color="primary"
              />
            )}
            {dataDocument === undefined && (
              <Box className={classes.boxWarning}>
                <InfoIcon
                  style={{ color: '#856404' }}
                  fontSize="default"
                  className={classes.iconWarning}
                />
                <Typography className={classes.textInfo}>
                  Número de documento inválido!
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Paper style={{ color: '#000', width: '100%', height: 'auto' }}>
          {dataDocument && (
            <>
              <MyEditor
                disabled
                value={`${dataDocument?.cabecalho?.conteudo}
                  ${dataDocument?.conteudo}
                  ${addInfoSign()}
                  `}
                print
                addConf={{
                  width: '100%',
                  height: 800,
                }}
              />
            </>
          )}
        </Paper>
        {dataDocument && (
          <Button
            onClick={handleClear}
            className={classes.buttonClear}
            variant="outlined"
          >
            consultar outro documento
          </Button>
        )}
      </Box>
    </Box>
  )
}
