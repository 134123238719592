import {
  TbBookmark,
  TbFileInvoice,
  TbIdBadge2,
  TbList,
  TbShield,
  TbUserCircle,
} from 'react-icons/tb'

export const workspaceMenuItems = [
  {
    name: 'Minha conta',
    url: '/workspace',
    icon: TbUserCircle,
  },
  {
    name: 'Meus dados',
    url: '/workspace/meus-dados',
    icon: TbIdBadge2,
  },
  {
    name: 'Atividades',
    url: '/workspace/atividades',
    icon: TbList,
  },
  {
    name: 'Favoritos',
    url: '/workspace/favoritos',
    icon: TbBookmark,
  },
  {
    name: 'Minhas solicitações',
    url: '/workspace/minhas_solicitacoes',
    icon: TbFileInvoice,
  },
  {
    name: 'Privacidade',
    url: '/workspace/privacidade',
    icon: TbShield,
  },
]
