import Box from '@material-ui/core/Box'
import React from 'react'

import useColorfullBarStyles from './styles'

export default function ColorfullBar() {
  const { classes } = useColorfullBarStyles()

  const hasHomePath = window?.location?.pathname === '/'

  return (
    <Box
      className={`${classes.colorfullContainer} ${
        hasHomePath
          ? classes.heightContainerHomeScreen
          : classes.heightContainerOtherScreen
      }`}
    >
      <Box className={`${classes.coloredBox} ${classes.lightGreenBox}`} />
      <Box className={`${classes.coloredBox} ${classes.greenBox}`} />
      <Box className={`${classes.coloredBox} ${classes.blueBox}`} />
      <Box className={`${classes.coloredBox} ${classes.orangeBox}`} />
      <Box className={`${classes.coloredBox} ${classes.pinkBox}`} />
    </Box>
  )
}
