import { makeStyles, Theme } from '@material-ui/core/styles'

export default function useColorfullBarStyles() {
  const useStyles = makeStyles((theme: Theme) => ({
    colorfullContainer: {
      width: '100%',
      display: 'flex',
    },
    heightContainerHomeScreen: {
      height: 16,
      [theme.breakpoints.down('xs')]: {
        height: 8,
      },
    },
    heightContainerOtherScreen: {
      height: 12,
      [theme.breakpoints.down('xs')]: {
        height: 8,
      },
    },
    coloredBox: {
      flex: 1,
      height: '100%',
    },
    lightGreenBox: {
      backgroundColor: '#75C973',
    },
    greenBox: {
      backgroundColor: '#27AC4F',
    },
    blueBox: {
      backgroundColor: '#0467B8',
    },
    orangeBox: {
      backgroundColor: '#FF7C24',
    },
    pinkBox: {
      backgroundColor: '#FA3198',
    },
  }))

  const classes = useStyles()

  return { classes }
}
