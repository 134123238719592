import {
  TbBrandFacebook,
  TbBrandInstagram,
  TbBrandTwitter,
  TbBrandYoutube,
} from 'react-icons/tb'

export function useLicenceData() {
  return {
    facebook: {
      link: process.env.REACT_APP_LINK_FACEBOOK ?? '',
      icon: TbBrandFacebook,
    },
    instagram: {
      link: process.env.REACT_APP_LINK_INSTAGRAM ?? '',
      icon: TbBrandInstagram,
    },
    twitter: {
      link: process.env.REACT_APP_LINK_TWITTER ?? '',
      icon: TbBrandTwitter,
    },
    youtube: {
      link: process.env.REACT_APP_LINK_YOUTUBE ?? '',
      icon: TbBrandYoutube,
    },
  }
}
