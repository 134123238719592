import { AxiosResponse } from 'axios'

import { apiosm } from './api'

export const getGeolocationByCEP = (
  cep: string | number,
): Promise<AxiosResponse> =>
  apiosm.get(`search?format=json&postalcode=${cep}&country=Brazil`)

export const getGeolocationByLatLng = (
  latitude,
  longitude,
): Promise<AxiosResponse> =>
  apiosm.get(`reverse?lat=${latitude}&lon=${longitude}&format=json`)
