import React from 'react'

export default function Icon(): JSX.Element {
  return (
    <svg
      width="56"
      height="55"
      viewBox="0 0 56 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 28C31.713 28 35.274 26.525 37.8995 23.8995C40.525 21.274 42 17.713 42 14C42 10.287 40.525 6.72601 37.8995 4.10051C35.274 1.475 31.713 0 28 0C24.287 0 20.726 1.475 18.1005 4.10051C15.475 6.72601 14 10.287 14 14C14 17.713 15.475 21.274 18.1005 23.8995C20.726 26.525 24.287 28 28 28ZM25.8125 18.375H27.125V14.875H25.8125C25.0852 14.875 24.5 14.2898 24.5 13.5625C24.5 12.8352 25.0852 12.25 25.8125 12.25H28.4375C29.1648 12.25 29.75 12.8352 29.75 13.5625V18.375H30.1875C30.9148 18.375 31.5 18.9602 31.5 19.6875C31.5 20.4148 30.9148 21 30.1875 21H25.8125C25.0852 21 24.5 20.4148 24.5 19.6875C24.5 18.9602 25.0852 18.375 25.8125 18.375ZM28 7C28.4641 7 28.9092 7.18437 29.2374 7.51256C29.5656 7.84075 29.75 8.28587 29.75 8.75C29.75 9.21413 29.5656 9.65925 29.2374 9.98744C28.9092 10.3156 28.4641 10.5 28 10.5C27.5359 10.5 27.0908 10.3156 26.7626 9.98744C26.4344 9.65925 26.25 9.21413 26.25 8.75C26.25 8.28587 26.4344 7.84075 26.7626 7.51256C27.0908 7.18437 27.5359 7 28 7Z"
        fill="white"
      />
      <path
        d="M54.4159 43.0195C56.1464 41.7459 56.5159 39.3153 55.2423 37.5847C53.9686 35.8542 51.5381 35.4847 49.8075 36.7583L38.1699 45.3334H26.4447C25.5892 45.3334 24.8892 44.6334 24.8892 43.7779C24.8892 42.9223 25.5892 42.2223 26.4447 42.2223H28.0003H34.2226C35.9434 42.2223 37.3337 40.832 37.3337 39.1111C37.3337 37.3903 35.9434 36 34.2226 36H28.0003H26.4447H18.8321C16.003 36 13.2613 36.9625 11.0543 38.7223L6.68896 42.2223H3.11115C1.39029 42.2223 0 43.6126 0 45.3334V51.5557C0 53.2766 1.39029 54.6669 3.11115 54.6669H18.6669H34.2712C37.0907 54.6669 39.8421 53.7627 42.1171 52.0905L54.4256 43.0195H54.4159ZM18.8224 42.2126C18.8267 42.2195 18.8291 42.2275 18.8295 42.2357C18.8299 42.2438 18.8282 42.252 18.8246 42.2593C18.821 42.2667 18.8156 42.273 18.8089 42.2777C18.8022 42.2824 18.7945 42.2853 18.7863 42.2862C18.7782 42.2871 18.77 42.286 18.7624 42.2829C18.7548 42.2797 18.7482 42.2748 18.7431 42.2684C18.7379 42.262 18.7345 42.2544 18.7331 42.2464C18.7317 42.2383 18.7323 42.23 18.7349 42.2223C18.7307 42.2153 18.7282 42.2074 18.7278 42.1992C18.7275 42.191 18.7291 42.1829 18.7327 42.1755C18.7363 42.1682 18.7417 42.1619 18.7484 42.1572C18.7551 42.1525 18.7629 42.1495 18.771 42.1486C18.7792 42.1477 18.7874 42.1489 18.795 42.152C18.8025 42.1551 18.8092 42.1601 18.8143 42.1665C18.8194 42.1729 18.8228 42.1804 18.8243 42.1885C18.8257 42.1965 18.8251 42.2048 18.8224 42.2126Z"
        fill="white"
      />
    </svg>
  )
}
