import { makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useContext } from 'react'

import colorContext from '../../Context/colorContext'

export function useFooterStyles() {
  const isLargeScreen = useMediaQuery('(max-width:1250px)')
  const isSmallScreen = useMediaQuery('(max-width: 660px)')
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    main: {
      flex: 1,
    },
    footer: {
      width: '100%',
      borderTop: '1px solid #bdbdbd',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'center',
      background: colors.cardAccentColor,
    },
    title: {
      color: colors.textAccentColor,
      fontSize: '1rem',
      fontWeight: 800,
      marginTop: 60,
    },
    option: {
      color: colors.textAccentColor,
      fontSize: '0.8rem',
      fontWeight: 300,
    },
    buttonSocial: {
      backgroundColor: colors.colorBackground,
      marginRight: 10,
      height: 38,
      width: 38,
      minWidth: 0,
      borderRadius: 4,
      color: colors.accentColor,
      '&:hover': {
        background: colors.cardAccentColor,
        color: colors.textAccentColor,
      },
    },
    button: {
      justifyContent: 'flex-start',
    },
    boxInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: 12,
      color: colors.textAccentColor,
      fontSize: isSmallScreen ? 16 : 18,
      fontWeight: 500,
    },
    boxIcon: {
      width: isSmallScreen ? 16 : 40,
      height: isSmallScreen ? 16 : 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackgroundSecundary,
      borderRadius: '50%',
    },
    boxSocialMedia: {
      display: 'flex',
      flexDirection: isSmallScreen ? 'column' : 'row',
      alignItems: 'center',
      gap: 9,
    },
    titleBoxSocialMedia: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.textAccentColor,
      fontWeight: 400,
    },
    boxSocialMediaItems: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    btnSocial: {
      backgroundColor: colors.colorBackgroundSecundary,
      color: colors.accentColor,
      padding: 0,
      width: 40,
      height: 40,
      '&:hover': {
        backgroundColor: colors.colorBackgroundSecundary,
      },
    },
    btnFacebook: {
      color: colors.textAccentColor,
      padding: 0,
      width: 40,
      height: 40,
    },
    iconSocial: {
      width: 22,
      height: 22,
    },
    icon: {
      width: 20,
      height: 20,
    },
    boxPlataforms: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 24,
      width: '100%',
    },
    plataform: {
      display: 'flex',
      alignItems: 'center',
      gap: 13,
      backgroundColor: colors.colorBackground,
      borderRadius: 56,
      padding: '12px 30px 12px 20px',
      width: isLargeScreen ? 230 : '100%',
    },
    titleItemFooter: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      textTransform: 'uppercase',
      textAlign: isSmallScreen ? 'center' : 'right',
      color: colors.textAccentColor,
      fontFamily: 'Roboto',
      lineHeight: '1.5rem',
    },
    itemFooter: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 380,
      width: '100%',
      minHeight: 100,
      margin: '0px 15px',
      alignItems: 'end',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },
    textItemFooter: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: colors.textAccentColor,
      textAlign: isSmallScreen ? 'center' : 'right',
      lineHeight: '1.5rem',
    },
    listServices: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: isSmallScreen ? 'center' : 'flex-start',
      gap: 16,
      margin: 0,
      padding: 0,
    },
    serviceItem: {
      width: 'auto',
      padding: 0,
      '&::before': {
        content: '""',
        display: 'block',
        width: 8,
        height: 8,
        borderRadius: '50%',
        marginRight: 8,
        backgroundColor: '#fff',
      },
    },
    location: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: colors.textAccentColor,
      textDecoration: 'none',
      lineHeight: '1.5rem',
    },
    textEmail: {
      margin: '1rem 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      lineHeight: '1rem',
      color: colors.textAccentColor,
    },
    mail: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      color: 'inherit',
      textDecoration: 'none',
      fontStyle: 'normal',
    },
  }))

  const classes = useStyles()

  return { classes, isLargeScreen, isSmallScreen }
}
