import {
  TbBrandFacebook,
  TbBrandInstagram,
  TbBrandX,
  TbBrandYoutube,
} from 'react-icons/tb'

export const socialNetworksItems = [
  {
    link: process.env.REACT_APP_LINK_FACEBOOK ?? '',
    icon: TbBrandFacebook,
  },
  {
    link: process.env.REACT_APP_LINK_INSTAGRAM ?? '',
    icon: TbBrandInstagram,
  },
  {
    link: process.env.REACT_APP_LINK_TWITTER ?? '',
    icon: TbBrandX,
  },
  {
    link: process.env.REACT_APP_LINK_YOUTUBE ?? '',
    icon: TbBrandYoutube,
  },
]
