import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

import AsyncSelect, { DataProps } from '../../../AsyncSelect'

interface SearchServiceSelectPros {
  handleSetService: (value: DataProps) => void
  handleSearchService: (value: string) => void
  searchService: DataProps
  validateQueryService: boolean
}

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: 200,
    margin: '10px 0px',
  },
}))

export default function SearchServiceSelect({
  handleSearchService,
  handleSetService,
  searchService,
  validateQueryService,
}: SearchServiceSelectPros): JSX.Element {
  const classes = useStyles()
  return (
    <div id="input-unidade" className={classes.container}>
      {process.env.REACT_APP_AGENDAMENTO_ATIVO === 'true' ? (
        <AsyncSelect
          id="search-service"
          name="scheduling"
          onChange={handleSetService}
          placeholder="Procurar serviço"
          value={searchService}
          loadOptions={handleSearchService}
          noDataMessage="Nenhum serviço encontrado"
          validate={validateQueryService}
        />
      ) : (
        <Box display="flex" width="100%" justifyContent="center">
          <Box
            width="100%"
            maxWidth="1320px"
            padding="10px 10px"
            display="flex"
            alignItems="center"
          >
            <InfoIcon
              style={{ fontSize: 30, color: '#053396', marginRight: 10 }}
            />
            <Box>
              <Typography style={{ fontWeight: '500' }}>
                Os agendamentos dos serviços estão momentaneamente indisponíveis
                por este canal, devendo ser realizados através do sistema{' '}
                <a
                  href="https://horamarcada.salvador.ba.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Hora Marcada
                </a>
                .
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  )
}
