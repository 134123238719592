import Box from '@material-ui/core/Box'
import Theme from '@material-ui/core/styles/MuiThemeProvider'
import InfoIcon from '@material-ui/icons/Info'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

interface AlertCardProps {
  isVisible: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  warnIcon: {
    color: '#ffa300',
    fontSize: theme.typography.pxToRem(19),
  },
  containerInfo: {
    maxWidth: 536,
    width: '100%',
    justifyContent: 'center',
  },
}))

export default function AlertCard({ isVisible }: AlertCardProps): JSX.Element {
  const classes = useStyles()
  return (
    <>
      {isVisible && (
        <Box className={classes.containerInfo}>
          <Alert
            icon={<InfoIcon className={classes.warnIcon} />}
            severity="warning"
          >
            <strong>Aviso: </strong>É necessário que o endereço da empresa seja
            do Município de Salvador
          </Alert>
        </Box>
      )}
    </>
  )
}
