/* eslint-disable react/require-default-props */
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import HomeIcon from '@material-ui/icons/Home'
import ScheduleIcon from '@material-ui/icons/Schedule'
import { makeStyles } from '@material-ui/styles'
import React, { Fragment, useContext } from 'react'
import {
  TbBorderAll,
  TbCategory,
  TbClockHour4,
  TbHeadset,
  TbNews,
  TbPlayerPlay,
} from 'react-icons/tb'
import { useHistory, useLocation } from 'react-router-dom'

import colorContext from '../../Context/colorContext'
import refContext from '../../Context/refContext'

interface Props {
  handleClose?: () => void
  handleOpenModal: () => void
}

export default function Menu({
  handleClose,
  handleOpenModal,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext)
  const matches = useMediaQuery('(min-width:1250px)')
  const location = useLocation()
  const history = useHistory()

  const useStyles = makeStyles(() => ({
    linksBoxSubMenu: {
      fontSize: matches ? 16 : 14,
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: colors.textAccentColor,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    itemMenu: {
      padding: '15px 20px',
    },
    iconMenu: {
      width: matches ? 34 : 26,
      height: matches ? 34 : 26,
      border: '1px solid #FFF',
      background: 'rgba(255, 255, 255, 0.16)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
    },
    icon: {
      width: matches ? 24 : 16,
      height: matches ? 24 : 16,
    },
  }))

  const classes = useStyles()

  const {
    refCategory,
    refService,
    refAttendance,
    scrollToRef,
    refVideos,
    refNoticias,
  } = useContext(refContext)

  const MenuItems = [
    {
      titulo: 'Categorias',
      ref: matches ? refCategory : '#categorias',
      icon: (
        <Box component="span" className={classes.iconMenu}>
          <TbCategory className={classes.icon} fontSize="medium" />
        </Box>
      ),
    },
    {
      titulo: 'Serviços em Destaque',
      ref: matches ? refService : '#servicos',
      icon: (
        <Box component="span" className={classes.iconMenu}>
          <TbBorderAll className={classes.icon} fontSize="medium" />
        </Box>
      ),
    },
    {
      titulo: 'Canais de Contato',
      ref: matches ? refAttendance : '#contato',
      icon: (
        <Box component="span" className={classes.iconMenu}>
          <TbHeadset className={classes.icon} fontSize="medium" />
        </Box>
      ),
    },
    {
      titulo: 'Agendamentos',
      onclick: handleOpenModal,
      icon: (
        <Box component="span" className={classes.iconMenu}>
          <TbClockHour4 className={classes.icon} fontSize="medium" />
        </Box>
      ),
    },
    {
      titulo: 'Notícias',
      ref: matches ? refNoticias : '#noticias',
      icon: (
        <Box component="span" className={classes.iconMenu}>
          <TbNews className={classes.icon} fontSize="medium" />
        </Box>
      ),
    },
    {
      titulo: 'Vídeos',
      ref: matches ? refVideos : '#videos',
      icon: (
        <Box component="span" className={classes.iconMenu}>
          <TbPlayerPlay className={classes.icon} fontSize="medium" />
        </Box>
      ),
    },
  ]

  const returnFalse = () => false

  const handleClick = (item: any) => {
    if (handleClose) handleClose()
    if (location.pathname !== '/') {
      item.onclick()
    } else if (item.titulo !== 'Agendamentos') {
      scrollToRef(item.ref)
    } else {
      item.onclick()
    }
  }

  return (
    <List style={{ paddingTop: 0 }}>
      {location.pathname === '/' ? (
        <>
          {MenuItems.map((item, index) => (
            <Fragment key={`key-${item.titulo}`}>
              <ListItem className={classes.itemMenu}>
                {!matches && item.titulo !== 'Agendamentos' ? (
                  <a
                    href={item.ref}
                    onClick={() => {
                      if (handleClose) {
                        handleClose()
                      }
                    }}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Typography
                      component="span"
                      className={classes.linksBoxSubMenu}
                      style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                    >
                      <div>{item.icon}</div>
                      {item.titulo}
                    </Typography>
                  </a>
                ) : (
                  <Typography
                    id={item.titulo}
                    className={classes.linksBoxSubMenu}
                    style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                    onClick={() => handleClick(item)}
                    onDoubleClick={returnFalse}
                  >
                    <span>{item.icon}</span>
                    {item.titulo}
                  </Typography>
                )}
              </ListItem>
              {!matches && (
                <>
                  {MenuItems.length - 1 === index ? (
                    <></>
                  ) : (
                    <Divider
                      style={{ backgroundColor: 'rgba(255, 255, 255, 0.16)' }}
                    />
                  )}
                </>
              )}
            </Fragment>
          ))}
        </>
      ) : (
        <>
          <ListItem className={classes.itemMenu}>
            <Typography
              className={classes.linksBoxSubMenu}
              style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
              onClick={() => {
                if (handleClose) handleClose()
                history.push('/')
              }}
            >
              <Box className={classes.iconMenu}>
                <HomeIcon className={classes.icon} fontSize="medium" />
              </Box>
              Página Inicial
            </Typography>
          </ListItem>
          <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.16)' }} />
          <ListItem className={classes.itemMenu}>
            <Typography
              className={classes.linksBoxSubMenu}
              style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
              onClick={() => {
                if (handleClose) handleClose()
                handleOpenModal()
              }}
              onDoubleClick={returnFalse}
            >
              <Box className={classes.iconMenu}>
                <ScheduleIcon className={classes.icon} fontSize="medium" />
              </Box>
              Agendamentos
            </Typography>
          </ListItem>
        </>
      )}
    </List>
  )
}
