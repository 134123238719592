import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { useContext, useEffect, useState } from 'react'

import colorContext from '../../Context/colorContext'
import getCookie from '../../utils/getCookies'
import CookieIcon from '../Icons/Cookie'

export interface INotice {
  titulo: string
  conteudo: string
  id: number
}

export default function AlertAcceptCookies(): JSX.Element {
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      backgroundColor: colors.colorBackground,
    },
    boxAlert: {
      zIndex: 1000,
      position: 'fixed',
      bottom: '2rem',
      left: 0,
      margin: '0 20px',
    },
    iconCookie: {
      marginLeft: 10,
      marginRight: 20,
      padding: 11,
      width: 56,
      height: 56,
      borderRadius: '50%',
    },
    alert: {
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.16)',
      backgroundColor: colors.colorBackgroundSecundary,
      maxWidth: 400,
      width: '100%',
      borderRadius: 5,
      border: `1px solid ${colors.borderShadowsColor}`,
      fontFamily: 'Roboto',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem',
      animation: `$slideAlertLeft 0.5s ${theme.transitions.easing.easeOut}`,
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
    text: {
      padding: 14,
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 400,
      color: colors.textBody,
      textAlign: 'center',
    },
    textLink: {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 500,
      color: colors.textBody,
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
    '@keyframes slideAlertLeft': {
      '0%': {
        opacity: 0,
        transform: 'translateX(-10rem)',
      },
      '100%': {
        opacity: 1,
        transform: 'translateX(0)',
      },
    },
    buttonAccept: {
      height: 40,
      borderRadius: 4,
      padding: '12px 16px',
      marginTop: 10,
      marginBottom: 20,
      backgroundColor: colors.badgeColor,
      width: '100%',
      maxWidth: 326,
      '&:hover': {
        backgroundColor: colors.accentColorBackground,
      },
    },
    textButton: {
      color: colors.textAccentColor,
      fontFamily: 'Roboto',
      fontWeight: 700,
      textTransform: 'uppercase',
      fontSize: 14,
    },
    titleDialog: {
      color: colors.textBody,
      backgroundColor: colors.colorBackgroundSecundary,
      paddingTop: 20,
      paddingBottom: 20,
      paddingRight: 40,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: colors.textBody,
    },
    contentDialog: {
      color: colors.textBody,
      textAlign: 'justify',
      lineHeight: '19px',
    },
  }))
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const [openInfoModal, setInfoModal] = useState<boolean>(false)

  const handleAccept = () => {
    document.cookie = 'accept-cookies=accept;path=/'
    setOpen(false)
  }

  const handleClose = () => {
    setInfoModal(false)
  }

  useEffect(() => {
    const alertCookie = getCookie('accept-cookies')
    if (alertCookie === 'accept') {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [open])

  return (
    <>
      {open && (
        <Box className={classes.boxAlert}>
          <Box className={classes.alert}>
            <Box className={classes.iconCookie}>
              <CookieIcon styles={{ color: colors.accentColor }} size={33} />
            </Box>
            <Typography className={classes.text}>
              Ao usar nosso site, você concorda com nossa{' '}
              <Typography
                onClick={() => setInfoModal(true)}
                className={classes.textLink}
              >
                Política de Cookies.
              </Typography>
            </Typography>
            <Button
              className={classes.buttonAccept}
              variant="contained"
              onClick={handleAccept}
            >
              <Typography className={classes.textButton}>Entendi</Typography>
            </Button>
          </Box>
        </Box>
      )}
      <Dialog
        open={openInfoModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className={classes.titleDialog}>
          Política de Cookies
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers classes={{ root: classes.root }}>
          <Box className={classes.contentDialog}>
            <h3>O que são cookies?</h3>

            <p>
              Cookies são arquivos salvos em dispositivos como computador,
              tablet ou telefone quando você acessa um site. O uso dos cookies
              são necessários com objetivo de melhorar da melhor maneira o
              funcionamento do site e também para o aprimoramento dos serviços
              que compõe o site.
            </p>

            <p>
              Alguns cookies são classificados como necessários e permitem a
              funcionalidade central, como segurança, gerenciamento de rede e
              acessibilidade. Estes cookies podem ser coletados e armazenados
              assim que você inicia sua navegação ou quando usa algum recurso
              que os requer. Iremos descrever a seguir os tipos de cookies.
            </p>

            <h2>1. Cookies Primários</h2>

            <p>
              Alguns cookies serão colocados em seu dispositivo diretamente pelo
              nosso site, são conhecidos como cookies primários. Eles são
              essenciais para você navegar no site e usar seus recursos.
            </p>

            <h3>1.1 Temporários</h3>

            <p>
              Nós utilizamos cookies de sessão. Eles são temporários e expiram
              quando você fecha o navegador ou quando a sessão termina.
            </p>

            <h4>1.1.1 Finalidade</h4>

            <p>
              Estabelecer controle de idioma e segurança ao tempo da sessão.
            </p>

            <h3>1.2 Persistentes</h3>

            <p>
              Utilizamos também cookies persistentes que permanecem em seu disco
              rígido até que você os apague ou seu navegador o faça, dependendo
              da data de expiração do cookie. Todos os cookies persistentes têm
              uma data de expiração gravada em seu código, mas sua duração pode
              variar.
            </p>

            <h4>1.2.1 Finalidade</h4>

            <p>Coletam e armazenam a ciência sobre o uso de cookies no site.</p>

            <h2>2. Cookies de Terceiros</h2>

            <p>
              Outros cookies são colocados no seu dispositivo não pelo site que
              você está visitando, mas por terceiros, como, por exemplo, os
              sistemas analíticos.
            </p>

            <h3>2.1 Temporários</h3>

            <p>
              Nós utilizamos cookies de sessão. Eles são temporários e expiram
              quando você fecha o navegador ou quando a sessão termina.
            </p>

            <h4>2.1.1 Finalidade</h4>

            <p>
              Coletam informações sobre como você usa o site, como as páginas
              que você visitou e os links em que clicou. Nenhuma dessas
              informações pode ser usada para identificá-lo. Seu único objetivo
              é possibilitar análises e melhorar as funções do site.
            </p>

            <h3>2.2 Persistentes</h3>

            <p>
              Utilizamos também cookies persistentes que permanecem em seu disco
              rígido até que você os apague ou seu navegador o faça, dependendo
              da data de expiração do cookie. Todos os cookies persistentes têm
              uma data de expiração gravada em seu código, mas sua duração pode
              variar.
            </p>

            <h4>2.2.1 Finalidade</h4>

            <p>
              Coletam informações sobre como você usa o site, como as páginas
              que você visitou e os links em que clicou. Nenhuma dessas
              informações pode ser usada para identificá-lo. Seu único objetivo
              é possibilitar análises e melhorar as funções do site. Cookies
              podem ser desabilitados, alterando as configurações de seu
              navegador, entretanto, isso pode afetar o funcionamento do acesso
              e navegação ao site.
            </p>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
