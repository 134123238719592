import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Theme from '@material-ui/core/styles/MuiThemeProvider'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import StarIcon from '@material-ui/icons/Star'
import StarOutlineIcon from '@material-ui/icons/StarOutline'
import { Rating } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import React, { useContext, useState } from 'react'

import colorContext from '../../Context/colorContext'
import evaluationMain from '../../services/avaliacao'
import getCookie from '../../utils/getCookies'
import { UserData } from '../AttendenceOnline'

interface Props {
  onPermittedCloseModal: () => void
  setEvaluationCompleted: React.Dispatch<React.SetStateAction<boolean>>
  setHiddenWarning: React.Dispatch<React.SetStateAction<boolean>>
  evaluationCompleted: boolean
  serviceSelected: any
  tipoAvaliacao:
    | 'Agendamento'
    | 'Formflow'
    | 'Solicitação'
    | 'Manifestação'
    | 'Esic'
}

export default function SchedulingAssessment({
  setEvaluationCompleted,
  evaluationCompleted,
  serviceSelected,
  tipoAvaliacao,
  setHiddenWarning,
}: Props): JSX.Element {
  const [description, setDescription] = useState<string | null>('')
  const [descriptionPlatform, setDescriptionPlatform] = useState<string | null>(
    '',
  )
  const [step, setStep] = useState<number>(0)
  const [ratingPlatform, setRatingPlatform] = React.useState<number>(5)
  const [ratingScheduling, setRatingScheduling] = React.useState<number>(5)
  const [loading, setLoading] = useState<boolean>(false)
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles((theme: Theme) => ({
    concludedContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    evaluationContent: {
      display: 'flex',
      width: '100%',
      border: '1px solid rgba(234, 236, 240, 1)',
      borderRadius: 4,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px 24px',
      marginBottom: 20,
      maxWidth: 520,
      height: 345,
      marginTop: '32px',
    },
    colorRatingIcon: {
      width: 36,
      height: 32,
      color: 'rgba(250, 176, 5, 1)',
    },
    ratingContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 24,
    },
    infoStepText: {
      fontSize: theme.typography.pxToRem(12),
      color: 'rgba(102, 112, 133, 1)',
      fontWeight: 400,
      fontFamily: 'Roboto',
      marginBottom: 8,
      marginTop: 24,
    },
    titleRatingText: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      marginBottom: 8,
      textAlign: 'center',
    },
    descriptionRatingText: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
      color: 'rgba(102, 112, 133, 1)',
      margin: 0,
      lineHeight: '20.02px',
    },
    ratingNumberText: {
      color: 'rgba(102, 112, 133, 1)',
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 400,
    },
    contentDescription: {
      marginTop: 15,
    },
    contentDescriptionInput: {
      color: '#1F2937',
      minWidth: 416,
      height: 56,
      overflow: 'auto',
    },
    buttonPrimary: {
      marginBottom: 15,
      backgroundColor: colors.accentColorBackground,
      color: colors.textAccentColor,
      width: 97,
      height: 36,
      '&:hover': {
        backgroundColor: colors.accentColorBackground,
      },
    },
    buttonPrimaryRegister: {
      marginBottom: 15,
      backgroundColor: colors.accentColorBackground,
      color: colors.textAccentColor,
      width: 110,
      height: 36,
      '&:hover': {
        backgroundColor: colors.accentColorBackground,
      },
    },
    textButtonPrimary: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  }))
  const classes = useStyles()

  const handleSubmit = () => {
    setStep((oldStep) => oldStep + 1)
    setHiddenWarning(true)
  }

  const handleRatingPlatform = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRatingPlatform(parseFloat(e.target.value))
  }
  const handleRatingScheduling = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRatingScheduling(parseFloat(e.target.value))
  }

  const sendEvaluation = async (): Promise<void> => {
    handleSubmit()
    setLoading(true)
    const token: string | null = getCookie('gov_access_token_sso')
    const dataStorage: string | null = getCookie('gov_user_data')
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null
    if (token && userData) {
      const avaliacao = {
        tipo: tipoAvaliacao,
        avaliacao_processo: ratingScheduling,
        comentario_processo: description,
        avaliacao_plataforma: ratingPlatform,
        comentario_plataforma: descriptionPlatform,
        servico: serviceSelected.id,
        orgao: null,
        token,
      }
      try {
        await evaluationMain(avaliacao)
        setEvaluationCompleted(false)
      } catch (err) {
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      {loading ? (
        <>
          <Box style={{ marginTop: 20, textAlign: 'center' }}>
            <CircularProgress color="primary" />
          </Box>
        </>
      ) : (
        <>
          <Box className={classes.evaluationContent}>
            {step === 0 && (
              <>
                <Typography className={classes.infoStepText}>1/2</Typography>
                <Typography className={classes.titleRatingText}>
                  Avalie seu Agendamento
                </Typography>
                <p className={classes.descriptionRatingText}>
                  Sua opinião é importante para nós!
                </p>
                <Box className={classes.ratingContent}>
                  <Rating
                    id="rate-stars"
                    size="large"
                    value={ratingScheduling}
                    onChange={handleRatingScheduling}
                    name="simple-controlled"
                    icon={
                      <Box padding="10px">
                        <StarIcon className={classes.colorRatingIcon} />
                      </Box>
                    }
                    emptyIcon={
                      <Box padding="10px">
                        <StarOutlineIcon className={classes.colorRatingIcon} />
                      </Box>
                    }
                  />
                  <span className={classes.ratingNumberText}>
                    {ratingScheduling} estrela(s)
                  </span>
                  <TextField
                    id="optional-comment"
                    label={
                      ratingScheduling <= 3
                        ? 'Por favor, descreva o motivo da sua insatisfação ( Obrigatório )'
                        : 'Você gostaria de deixar um comentário? (Opcional)'
                    }
                    multiline
                    fullWidth
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    variant="outlined"
                    maxRows={2}
                    className={classes.contentDescription}
                    inputProps={{
                      className: classes.contentDescriptionInput,
                    }}
                  />
                </Box>
                <Button
                  id="next-button"
                  disabled={ratingScheduling <= 3 && !description}
                  onClick={handleSubmit}
                  className={classes.buttonPrimary}
                >
                  <Typography className={classes.textButtonPrimary}>
                    Próximo
                  </Typography>
                </Button>
              </>
            )}

            {step === 1 && (
              <>
                <Typography className={classes.infoStepText}>2/2</Typography>
                <Typography className={classes.titleRatingText}>
                  Avalie a Plataforma Salvador Digital
                </Typography>
                <p className={classes.descriptionRatingText}>
                  Suas estrelas nos ajudam a melhorar nossos serviços!
                </p>
                <Box className={classes.ratingContent}>
                  <Rating
                    id="rate-stars"
                    size="large"
                    value={ratingPlatform}
                    onChange={handleRatingPlatform}
                    name="simple-controlled"
                    icon={
                      <Box padding="10px">
                        <StarIcon className={classes.colorRatingIcon} />
                      </Box>
                    }
                    emptyIcon={
                      <Box padding="10px">
                        <StarOutlineIcon className={classes.colorRatingIcon} />
                      </Box>
                    }
                  />
                  <span className={classes.ratingNumberText}>
                    {ratingPlatform} estrela(s)
                  </span>
                  <TextField
                    id="optional-comment"
                    label={
                      ratingPlatform <= 3
                        ? 'Por favor, descreva o motivo da sua insatisfação ( Obrigatório )'
                        : 'Você gostaria de deixar um comentário? (Opcional)'
                    }
                    multiline
                    fullWidth
                    value={descriptionPlatform}
                    onChange={(event) =>
                      setDescriptionPlatform(event.target.value)
                    }
                    variant="outlined"
                    maxRows={2}
                    className={classes.contentDescription}
                    inputProps={{
                      className: classes.contentDescriptionInput,
                    }}
                  />
                </Box>
                <Button
                  id="register-button"
                  disabled={ratingPlatform <= 3 && !descriptionPlatform}
                  onClick={sendEvaluation}
                  className={classes.buttonPrimaryRegister}
                >
                  <Typography className={classes.textButtonPrimary}>
                    Registrar
                  </Typography>
                </Button>
              </>
            )}
            <div hidden={evaluationCompleted}>
              <Box width="100%">
                <img
                  src="/assets/images/feedback-rating.svg"
                  width="100%"
                  height="auto"
                  alt="favorite"
                />
              </Box>
              <Typography className={classes.titleRatingText}>
                Sua opinião foi registrada!
              </Typography>
              <p className={classes.descriptionRatingText}>
                Agradecemos por contribuir
              </p>
              {/* <Button onClick={handleConclude} className={classes.buttonPrimary}>
            <Typography className={classes.textButtonPrimary}>
              CONCLUIR
            </Typography>
              </Button> */}
            </div>
          </Box>
        </>
      )}
    </>
  )
}
