/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

interface RegistryProps {
  typeIdentification: 'Anônimo' | 'Identificado' | 'Sigiloso'
  esicCode: {
    code: string
    protocol: string
  }
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
}

export default function Registry({
  typeIdentification,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  esicCode,
  setActiveStep,
}: RegistryProps): JSX.Element {
  const useStyles = makeStyles((theme: Theme) => ({
    boxInformation: {
      maxWidth: 1320,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '0px 10px 122px 10px',
      margin: '24px auto 0px auto',
      [theme.breakpoints.down(660)]: {
        marginTop: 40,
        flexDirection: 'column-reverse',
        alignItems: 'center',
        padding: '0px 16px 40px 16px',
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(36),
      fontWeight: 700,
      color: '#333',
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    thank: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
      color: '#333',
      display: 'block',
      textAlign: 'left',
      [theme.breakpoints.down(660)]: {
        textAlign: 'center',
      },
    },
    codeTitle: {
      fontSize: theme.typography.pxToRem(13),
      color: '#333',
      fontWeight: 400,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(12),
        textAlign: 'center',
      },
    },
    code: {
      fontSize: theme.typography.pxToRem(24),
      color: '#333',
      fontWeight: 500,
      [theme.breakpoints.down(660)]: {
        textAlign: 'center',
      },
    },
    wanted: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: '#333',
      display: 'block',
      margin: '24px 0px 57px 0px',
      maxWidth: 600,
      lineHeight: '25.6px',
      [theme.breakpoints.down(768)]: {
        fontSize: theme.typography.pxToRem(15),
      },
      [theme.breakpoints.down(660)]: {
        textAlign: 'center',
      },
    },
    button: {
      minWidth: 258,
      textAlign: 'center',
      backgroundColor: theme.palette.primary.main,
      textDecoration: 'none',
      border: 'none',
      borderRadius: 4,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
      color: '#fff',
      textTransform: 'uppercase',
      padding: '12px 30px',
      fontFamily: 'Roboto',
      cursor: 'pointer',
      [theme.breakpoints.down(660)]: {
        display: 'block',
        width: '100%',
        padding: '12px 0px',
      },
    },
    boxQuery: {
      maxWidth: 1320,
      width: '100%',
      margin: '40px auto 0px auto',
      paddingBottom: '56px',
      padding: '0px 10px',
    },
    queryTitle: {
      fontSize: theme.typography.pxToRem(24),
      color: '#333',
      fontWeight: 700,
      marginBottom: 16,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: 'center',
      },
    },
    queryDescription: {
      fontSize: theme.typography.pxToRem(16),
      color: '#333',
      fontWeight: 500,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(15),
        textAlign: 'center',
      },
    },
    queryLink: {
      fontWeight: 500,
      color: '#333',
    },
    ImageConfirmed: {
      width: 398,
      height: 398,
      [theme.breakpoints.down(768)]: {
        width: 330,
        height: 330,
      },
      [theme.breakpoints.down(660)]: {
        width: 215,
        height: 215,
      },
    },
    boxButton: {
      display: 'flex',
      gap: '20px',
    },
  }))
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <Box className={classes.boxInformation}>
        <Box marginTop="50px">
          <Typography className={classes.thank} variant="overline">
            Obrigado pela sua participação
          </Typography>
          <Typography className={classes.title}>
            Pedido registrado com sucesso
          </Typography>
          {(typeIdentification === 'Identificado' ||
            typeIdentification === 'Sigiloso') && (
            <>
              <span className={classes.wanted}>
                Pedido registrado com sucesso! Para acompanhar todas os seus
                pedidos através do sistema, acesse a opção "Meu pedidos" ou
                clique no botão abaixo:
              </span>
              <Box margin="31px 0px 40px 0px">
                <Typography className={classes.codeTitle}>
                  Número de protocolo
                </Typography>
                <Typography
                  className={classes.code}
                  style={{ marginBottom: 24 }}
                >
                  {esicCode?.protocol || ''}
                </Typography>
              </Box>
              <Box className={classes.boxButton}>
                <button
                  className={classes.button}
                  type="button"
                  onClick={() => history.push('/workspace/minhas_solicitacoes')}
                >
                  ir para meus pedidos
                </button>
                <button
                  className={classes.button}
                  type="button"
                  onClick={() => setActiveStep(0)}
                >
                  novo pedido
                </button>
              </Box>
            </>
          )}
        </Box>
        <img
          src="/assets/images/confirmed.svg"
          alt="Imagem de ilustrando registro do pedido"
          className={classes.ImageConfirmed}
        />
      </Box>
      {typeIdentification === 'Anônimo' && (
        <>
          <Divider />
          <Box className={classes.boxQuery}>
            <Typography className={classes.queryTitle}>
              Para consultar sue pedido
            </Typography>
            <Typography className={classes.queryDescription}>
              Vá em{' '}
              <Link to="/esic" className={classes.queryLink}>
                Consultar Pedido
              </Link>
              , e informe o Número de Protocolo e o Código de Acesso do Pedido.
            </Typography>
          </Box>
        </>
      )}
    </>
  )
}
