import { AxiosResponse } from 'axios'

import { apiformflow, apisiseci } from './api'

interface ParamsServices {
  page: number
  items_size: number
  ativo: boolean
  publico_especifico: string
  categoria_slug?: string
  search?: string
}

export const getAllServices = (page: number): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/servicos_busca/?page=${page}`)

export const getService = (slug: string): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/servicos_busca/?slug=${slug}`)

export const searchService = (
  slug: string,
  page: number,
): Promise<AxiosResponse> =>
  apisiseci.get(`cms/servicos_busca/?search=${slug.toUpperCase()}&page=${page}`)

export const searchServiceForOrgan = (
  slug: string,
  page: number,
): Promise<AxiosResponse> =>
  apisiseci.get(`cms/servicos_busca/?orgao=${slug}&ativo=true&page=${page}`)

export const serviceForCategory = (
  slug: string,
  page: number,
): Promise<AxiosResponse> =>
  apisiseci.get(
    `cms/servicos_busca/?categoria_slug=${slug}&ativo=true&page=${page}`,
  )

export const getServiceEmphasis = (): Promise<AxiosResponse> => {
  return apisiseci.get('/cms/serv_destaques/')
}

export const getServiceTypeUser = (
  publico?: string,
  page?: number,
): Promise<AxiosResponse> =>
  apisiseci.get(
    `${
      publico
        ? `cms/servicos_busca?publico_especifico=${publico}`
        : `/cms/servicos_busca/?page=${page}`
    }`,
  )

export const favoriteService = (
  servico: number,
  token: string,
): Promise<AxiosResponse> =>
  apisiseci.post(
    '/cms/servicos_favoritos/',
    {
      servico,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
export const getFavoriteService = (token: string): Promise<AxiosResponse> =>
  apisiseci.get('/cms/servicos_favoritos/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

export const removeFavoriteService = (
  servico: number,
  token: string,
): Promise<AxiosResponse> =>
  apisiseci.delete(`/cms/servicos_favoritos/${servico}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

export const ratingServiceAndComment = (
  service_id: number,
  avaliation: number,
  comments: string,
  token: string,
): Promise<AxiosResponse> =>
  apisiseci.post(
    'cms/avaliar_servico/',
    {
      servicos: service_id,
      avaliacao: avaliation,
      comentario: comments,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

export const validateDocument = (
  id_code: string,
  verify_hash: string,
): Promise<AxiosResponse> =>
  apiformflow.get(
    `/flow/validar-documento-resposta/?identificador=${id_code}&verificador=${verify_hash}`,
  )

export const getServiceWithParams = (
  params: ParamsServices,
  token: string,
): Promise<AxiosResponse> =>
  apisiseci.get('/cms/servicos_busca/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  })
