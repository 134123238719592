/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import DashboardIcon from '@material-ui/icons/Dashboard'
import Alert from '@material-ui/lab/Alert'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { TbArrowRightToArc, TbDotsVertical } from 'react-icons/tb'
import { Link as LinkRouter, useLocation } from 'react-router-dom'

import RefContext from '../../Context/refContext'
import {
  clearStorage,
  getAdditionalDataUserSso,
  getImageProfile,
  getUserData as getUserDataRequest,
  loginRedirectPrivider,
  logout,
} from '../../services/auth'
import { UserData } from '../../services/user'
import { cpfMask } from '../../utils/cpfFormat'
import getCookie from '../../utils/getCookies'
import { getInitialsFromName } from '../../utils/nameFormat'
import AlertAcceptCookies from '../AlertAcceptCookies'
import Menu from '../Menu'
import ModalAlertsDialog from '../ModalAlertsDialog'
import ModalNotAuth from '../ModalNotAuth'
import QuickSchedule from '../QuickSchedule'
import TermoDialog from '../TermoDialog'
import AlertDataComplements from './components/AlertDataComplements'
import MenuItems from './components/MenuItems'
import SocialNetworks from './components/SocialNetworks'
import SubHeader from './components/SubHeader'
import WorkspaceMenuItems from './components/WorkspaceMenuItems'
import useHeaderStyles from './styles'

interface Props {
  setPerfilUser: React.Dispatch<any>
}

export default function Header({ setPerfilUser }: Props): JSX.Element {
  const location = useLocation()
  const token: string | null = getCookie('gov_access_token_sso')
  const tokenSiseci: string | null = getCookie('gov_access_token_sso')
  const { colors, classes, matches, matchesMobile, laptop } = useHeaderStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [anchorElUser, setAnchorElUser] =
    React.useState<HTMLButtonElement | null>(null)
  const [anchorElUserPanel, setAnchorElUserPanel] =
    React.useState<HTMLButtonElement | null>(null)
  const [userData, setUserData] = useState<UserData | null>()
  const [message, setMessage] = useState<string>('')
  const [avatarUser, setAvatarUser] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isOpenOptions, setIsOpenOptions] = useState(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isAuth, setIsAuth] = useState<boolean>(true)
  const [openModalComplements, setOpenModalComplements] =
    useState<boolean>(false)
  const { refInicio } = useContext(RefContext)

  useEffect(() => {
    const { state } = location
    if (state as any) {
      setMessage((state as any).message)
    }
  }, [location])

  const getUserData = async (tokenSso: string, tokenSiseciReceive: string) => {
    try {
      setLoading(true)

      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive)
      setUserData(data)
      setPerfilUser(data)
      document.cookie = `gov_user_data=${encodeURIComponent(
        JSON.stringify(data),
      )};path=/`
      // setLoading(false);
    } catch (err) {
      clearStorage()
      if (err.response?.data) {
        setMessage(err.response.data.detail)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleVerifyDataComplements = async () => {
    if (token && userData) {
      try {
        const { data } = await getAdditionalDataUserSso(token, userData.cpf)
        if (data.complementar) {
          sessionStorage.setItem('@data-complements', 'true')
        } else {
          setOpenModalComplements(true)
          sessionStorage.setItem('@data-complements', 'false')
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          sessionStorage.setItem('@data-complements', 'false')
          setOpenModalComplements(true)
        }
      }
    }
  }

  const hasComplements = sessionStorage.getItem('@data-complements')

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci)
      setIsAuth(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci)
      setIsAuth(true)
    }
  }, [token, tokenSiseci])

  useEffect(() => {
    if (token && userData && !hasComplements) {
      handleVerifyDataComplements()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userData, hasComplements])

  useEffect(() => {
    if (hasComplements === 'false') {
      setOpenModalComplements(true)
    }
  }, [hasComplements])

  const handleClick = (
    event: React.MouseEvent<any>,
    type?: 'user' | 'access' | 'user-panel',
  ) => {
    switch (type) {
      case 'user':
        setAnchorElUser(event.currentTarget)
        break
      case 'user-panel':
        setAnchorElUserPanel(event.currentTarget)
        break
      default:
        setAnchorEl(event.currentTarget)
        break
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setAnchorElUser(null)
    setAnchorElUserPanel(null)
    setIsOpenMenu(false)
    setIsOpenOptions(false)
  }

  const logoutUser = () => {
    logout()
    setUserData(undefined)
    setAnchorElUser(null)
    setAnchorElUserPanel(null)
  }

  const handleOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu)
  }

  const handleOpenOptions = () => {
    setIsOpenOptions(!isOpenOptions)
  }

  useEffect(() => {
    const handleGetImageProfile = async () => {
      if (token) {
        const { data } = await getImageProfile(token)

        if (data?.results?.length > 0) {
          const image = data.results[0].arquivo
          setAvatarUser(image)
        }
      }
    }

    handleGetImageProfile()
  }, [token])

  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault()

  const loginRedirect = () => {
    const { pathname } = location
    loginRedirectPrivider(pathname)
  }

  const nameSplitOne = (name: string): string => {
    if ((name || '').split(' ').length > 2) {
      return `${name.split(' ')[0]}`
    }
    return name
  }

  const nameSplitTwo = (name: string): string => {
    if ((name || '').split(' ').length > 2) {
      return `${name.split(' ')[0]} ${name.split(' ')[1][0]}.`
    }
    return name
  }

  useEffect(() => {
    refInicio.current.scrollIntoView()
  }, [location])

  const handleCloseModal = () => {
    if (tokenSiseci) {
      setOpenModal(!openModal)
      setIsAuth(true)
    } else {
      setIsAuth(false)
      setOpenModal(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>Salvador Digital</title>
      </Helmet>

      <ModalNotAuth open={!isAuth} onClose={(): void => setIsAuth(true)} />

      <div
        ref={!matches ? refInicio : null}
        id="back-to-top-anchor"
        style={{ top: 0 }}
        className={classes.main}
      >
        <Snackbar
          autoHideDuration={3000}
          onClose={(): void => setMessage('')}
          open={!!message}
        >
          <Alert onClose={(): void => setMessage('')} severity="error">
            {message}
          </Alert>
        </Snackbar>
        <Popover
          className={classes.boxInfo}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItems
            setIsAuth={setIsAuth}
            handleClose={handleClose}
            classes={classes}
            matches={matches}
          />
        </Popover>

        {/* //Popover User */}
        <Popover
          open={Boolean(anchorElUser)}
          anchorEl={anchorElUser}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{
            top: 10,
          }}
        >
          <Box>
            {!matches && (
              <MenuItem
                onClick={(e): void => handleClick(e, 'user-panel')}
                className={classes.menuItem}
              >
                <DashboardIcon
                  style={{
                    color: colors.accentColor,
                    width: 24,
                    height: 24,
                  }}
                />
                <Typography className={classes.textMenuItem}>
                  Meu Painel
                </Typography>
              </MenuItem>
            )}

            <MenuItem onClick={logoutUser} className={classes.menuItem}>
              <TbArrowRightToArc
                style={{
                  color: colors.buttonErrorColor,
                  width: 24,
                  height: 24,
                }}
              />
              <Typography className={classes.textMenuItem}>Sair</Typography>
            </MenuItem>
          </Box>
        </Popover>

        {/* //Popover User Panel */}
        <Popover
          open={Boolean(anchorElUserPanel)}
          anchorEl={anchorElUserPanel}
          onClose={handleClose}
          anchorOrigin={{
            vertical: matches ? 'bottom' : 'top',
            horizontal: matches ? 'right' : 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{
            top: matches ? 10 : 0,
            left: matches ? 0 : -10,
          }}
        >
          <Box padding="8px">
            {userData && <WorkspaceMenuItems classes={classes} />}
          </Box>
        </Popover>
        <Box
          display="flex"
          flexDirection={!matches ? 'column' : 'row'}
          maxWidth="1600px"
          alignItems="center"
          width="100%"
        >
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <LinkRouter to="/">
              <img
                className={classes.img}
                alt="logo"
                src={process.env.REACT_APP_LOGO_PRIMARY}
              />
            </LinkRouter>
            {!laptop && (
              <Box
                {...(matches ? { ref: refInicio } : {})}
                id="back-to-top-anchor"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flex="1"
              >
                <Box
                  className={classes.contentMenuPrimary}
                  display="flex"
                  alignItems="center"
                >
                  <MenuItems
                    setIsAuth={setIsAuth}
                    handleClose={handleClose}
                    classes={classes}
                    matches={matches}
                  />
                </Box>
                <SocialNetworks classes={classes} />
              </Box>
            )}
            <IconButton
              type="button"
              size="medium"
              onClick={handleOpenOptions}
              className={classes.drawerSocialNetworksButton}
              style={{ padding: 10 }}
            >
              <TbDotsVertical fontSize={24} />
            </IconButton>
          </Box>
        </Box>

        <Drawer anchor="left" open={isOpenMenu} onClose={handleClose}>
          <Box className={classes.backdropMenu}>
            <Box className={classes.menuHeader}>
              <Typography className={classes.menuHeaderTitle}>Menu</Typography>
              <CloseIcon
                fontSize="medium"
                onClick={handleClose}
                style={{ cursor: 'pointer', color: colors.textAccentColor }}
              />
            </Box>
            <Menu
              handleOpenModal={handleCloseModal}
              handleClose={handleClose}
            />
          </Box>
        </Drawer>

        <Drawer anchor="left" open={isOpenOptions} onClose={handleClose}>
          <Box className={classes.backdropMenu}>
            <Box className={classes.menuHeader}>
              <Typography className={classes.menuHeaderTitle}>
                Mais opções
              </Typography>
              <CloseIcon
                fontSize="medium"
                onClick={handleClose}
                style={{ cursor: 'pointer', color: colors.textAccentColor }}
              />
            </Box>
            <List
              style={{
                padding: 0,
              }}
            >
              <MenuItems
                setIsAuth={setIsAuth}
                handleClose={handleClose}
                classes={classes}
                matches={matches}
                laptop={laptop}
              />
            </List>
          </Box>
        </Drawer>

        <QuickSchedule
          openModal={openModal}
          handleCloseModal={handleCloseModal}
        />
      </div>
      <SubHeader
        avatarUser={avatarUser}
        userData={userData}
        handleClick={handleClick}
        handleOpenMenu={handleOpenMenu}
        handleOpenOptions={handleOpenOptions}
        loading={loading}
        loginRedirect={loginRedirect}
      />
      <TermoDialog />
      <ModalAlertsDialog />
      <AlertAcceptCookies />
      <AlertDataComplements
        handleClose={() => setOpenModalComplements(false)}
        open={openModalComplements}
      />
    </>
  )
}
