import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { TbBaselineDensityMedium, TbChevronDown } from 'react-icons/tb'

import { cpfMask } from '../../../../utils/cpfFormat'
import {
  getInitialsFromName,
  nameSplitOne,
  nameSplitTwo,
} from '../../../../utils/nameFormat'
import useSubHeaderStyles from './styles'

type SubHeaderProps = {
  loading: boolean
  userData: any
  avatarUser: string
  handleOpenMenu: () => void
  handleOpenOptions: () => void
  handleClick: (e: React.MouseEvent<HTMLButtonElement>, route: string) => void
  loginRedirect: () => void
}

export default function SubHeader({
  loading,
  userData,
  avatarUser,
  handleOpenMenu,
  handleOpenOptions,
  handleClick,
  loginRedirect,
}: SubHeaderProps) {
  const { classes, colors, isLargeScreen, isSmallScreen } = useSubHeaderStyles()
  return (
    <Box component="header" className={classes.header}>
      <Box className={classes.container}>
        <Box className={classes.containerLeftSide}>
          <IconButton type="button" size="medium" onClick={handleOpenMenu}>
            <TbBaselineDensityMedium className={classes.iconDrawer} />
          </IconButton>
          <img
            className={classes.logo}
            alt="Logo Salvador Digital"
            style={{
              width: isLargeScreen ? 175 : 150,
            }}
            src="/assets/images/logo_salvador_digital.png"
          />
        </Box>
        <Box className={classes.containerRightSide}>
          {!isLargeScreen ? (
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="flex-end"
              style={{
                padding: '10px 0px',
              }}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    width: 25,
                    height: 25,
                    color: colors.textAccentColor,
                  }}
                />
              ) : (
                <>
                  {userData ? (
                    <Button
                      color="primary"
                      onClick={(e): void => handleClick(e, 'user')}
                      className={classes.containerAvatar}
                      startIcon={
                        <Avatar
                          src={avatarUser || null}
                          alt={getInitialsFromName(userData.nome || '')}
                          className={classes.avatar}
                        />
                      }
                      endIcon={<TbChevronDown />}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography
                          className={classes.textName}
                          color="primary"
                        >
                          {isSmallScreen
                            ? nameSplitTwo(userData?.nome?.toLowerCase())
                            : nameSplitOne(userData?.nome?.toLowerCase())}
                        </Typography>
                        <Typography color="primary" className={classes.textCpf}>
                          {cpfMask(userData.cpf)}
                        </Typography>
                      </Box>
                    </Button>
                  ) : (
                    <Button
                      color="default"
                      variant="outlined"
                      onClick={loginRedirect}
                      className={classes.buttonNotLogged}
                    >
                      <Typography className={classes.textButton}>
                        Entrar
                      </Typography>
                    </Button>
                  )}
                </>
              )}
            </Box>
          ) : (
            <Box>
              {loading ? (
                <CircularProgress
                  style={{
                    width: 25,
                    height: 25,
                    color: colors.textAccentColor,
                  }}
                />
              ) : userData ? (
                <Box gridGap={16} justifyContent="flex-end" display="flex">
                  <Button
                    color="primary"
                    onClick={(e): void => handleClick(e, 'user-panel')}
                    className={classes.userPanel}
                  >
                    Meu painel
                  </Button>
                  <Button
                    color="primary"
                    onClick={(e): void => handleClick(e, 'user')}
                    className={classes.containerAvatar}
                    startIcon={
                      <Avatar
                        src={avatarUser || null}
                        alt={getInitialsFromName(userData.nome || '')}
                        className={classes.avatar}
                      />
                    }
                    endIcon={
                      <TbChevronDown style={{ color: colors.accentColor }} />
                    }
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Typography className={classes.textName} color="primary">
                        {isSmallScreen
                          ? nameSplitTwo(userData?.nome?.toLowerCase())
                          : nameSplitOne(userData?.nome?.toLowerCase())}
                      </Typography>
                      <Typography color="primary" className={classes.textCpf}>
                        {cpfMask(userData.cpf)}
                      </Typography>
                    </Box>
                  </Button>
                </Box>
              ) : (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    color="default"
                    variant="outlined"
                    onClick={loginRedirect}
                    className={classes.buttonNotLogged}
                  >
                    <Typography className={classes.textButton}>
                      Entrar
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
