import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import React, { useMemo } from 'react'

import { useLicenceData } from './data'
import { useLicenceStyles } from './styles'

export default function Licence(): JSX.Element {
  const { classes } = useLicenceStyles()
  const { facebook, instagram, twitter, youtube } = useLicenceData()
  const currentYear = useMemo(() => new Date().getFullYear().toString(), [])

  const goToPage = (link: string) => {
    ;(window as any).open(link)
  }

  return (
    <Box className={classes.main}>
      <Box className={classes.container}>
        <Box className={classes.boxSocialMedia}>
          <Typography className={classes.titleBoxSocialMedia}>
            Siga a prefeitura:{' '}
          </Typography>
          <Box className={classes.boxSocialMediaItems}>
            <a href={facebook.link} rel="noreferrer noopener">
              <facebook.icon className={classes.iconSocial} />
            </a>
            <a href={instagram.link} rel="noreferrer noopener">
              <instagram.icon className={classes.iconSocial} />
            </a>
            <a href={twitter.link} rel="noreferrer noopener">
              <twitter.icon className={classes.iconSocial} />
            </a>
            <a href={youtube.link} rel="noreferrer noopener">
              <youtube.icon className={classes.iconSocial} />
            </a>
          </Box>
        </Box>
        <Typography className={classes.title}>
          {`${currentYear} ${process.env.REACT_APP_LICENCE}`}
        </Typography>
      </Box>
    </Box>
  )
}
