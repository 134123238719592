import { makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useContext } from 'react'

import colorContext from '../../Context/colorContext'

export function useLicenceStyles() {
  const isSmallScreen = useMediaQuery('(max-width: 660px)')
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 80,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.accentColorBackground,
      color: colors.textBody,
      padding: isSmallScreen ? '0px 20px' : '0px',
    },
    container: {
      display: 'flex',
      maxWidth: '1320px',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    title: {
      fontSize: isSmallScreen
        ? theme.typography.pxToRem(14)
        : theme.typography.pxToRem(16),
      fontWeight: 400,
      color: colors.textAccentColor,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      lineHeight: '1.5rem',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        maxWidth: 295,
        margin: '2.5rem 0px',
        textAlign: 'center',
      },
    },
    nameCompany: {
      fontSize: theme.typography.pxToRem(14),
    },
    boxSocialMedia: {
      display: 'flex',
      flexDirection: isSmallScreen ? 'column' : 'row',
      alignItems: 'center',
      gap: 9,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
      },
    },
    titleBoxSocialMedia: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.textAccentColor,
      fontWeight: 400,
    },
    boxSocialMediaItems: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    iconSocial: {
      width: 30,
      height: 30,
      color: colors.colorBackgroundSecundary,
    },
  }))
  const classes = useStyles()

  return { classes }
}
