import Fab from '@material-ui/core/Fab'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import RefContext from '../../../Context/refContext'
import { sendCodeForSISECI } from '../../../services/auth'
import Footer from '../../Footer'
import Header from '../../Header'
import Licence from '../../Licence'
import ColorfullBar from './components/ColorfullBar'
import ScrollTop from './ScrollTop'

interface Props {
  children: JSX.Element
}

const Dash: React.FC<Props> = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const [perfilUser, setPerfilUser] = useState(null)

  useEffect(() => {
    const url = window.location.search
    const { pathname } = location
    const query = new URLSearchParams(url)
    const code = query.get('code')
    if (code) {
      document.cookie = `pathname_redirect_gov=${encodeURIComponent(
        pathname,
      )};path=/`
      sendCodeForSISECI(code, history)
    }
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA}`
    document.body.appendChild(script)
  }, [history, location])

  const scrollToRef = (ref: React.MutableRefObject<any>) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView()
    }
  }

  const refCategory = useRef(null)
  const refApps = useRef(null)
  const refService = useRef(null)
  const refEstatistic = useRef(null)
  const refAttendance = useRef(null)
  const refNoticias = useRef(null)
  const refAgendaCultural = useRef(null)
  const refInicio = useRef(null)
  const refVideos = useRef(null)
  const refPersona = useRef(null)
  const refRegistryType = useRef(null)

  return (
    <RefContext.Provider
      value={{
        refCategory,
        refService,
        refEstatistic,
        refAttendance,
        refNoticias,
        refAgendaCultural,
        refInicio,
        refVideos,
        scrollToRef,
        refRegistryType,
        perfilUser,
        refApps,
        refPersona,
      }}
    >
      <Header setPerfilUser={setPerfilUser} />
      <ColorfullBar />
      {children}
      <Footer />
      <Licence />
      <ScrollTop>
        <Fab
          style={{
            right: 6,
            borderRadius: '50%',
            border: '1px solid #fff',
          }}
          size="large"
          color="primary"
          aria-label="scroll back to top"
        >
          <ArrowUpwardIcon style={{ color: '#fff', width: 24, height: 24 }} />
        </Fab>
      </ScrollTop>
    </RefContext.Provider>
  )
}

export default Dash
