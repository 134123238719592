import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'
import React, { ReactNode } from 'react'

const useStyles = makeStyles(() => ({
  containerActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: 536,
    gap: 10,
    margin: '15px 0px',
    width: '100%',
  },
}))

interface IdentificationActionsProps {
  children: ReactNode
}

export default function IdentificationActions({
  children,
}: IdentificationActionsProps): JSX.Element {
  const classes = useStyles()
  return <Box className={classes.containerActions}>{children}</Box>
}
