import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useContext, useState } from 'react'
import { TbHome, TbSearch } from 'react-icons/tb'
import { useHistory } from 'react-router-dom'

import colorContext from '../../../../Context/colorContext'
import theme from '../../../../services/theme-service'
import { SearchI } from '../../../SearchService'

interface Props {
  slug: string
  service: SearchI
}

export default function Breadcrumb({ slug, service }: Props): JSX.Element {
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff',
    },
    link: {
      display: 'flex',
    },
    icon: {
      marginRight: theme.spacing(0.5),
      color: theme.palette.primary.main,
      width: 30,
      height: 30,
    },
    inputSearch: {
      height: 50,
      borderRadius: 0,
      width: '100%',
      border: 'none',
      outline: 'none',
      paddingLeft: 16,
      color: colors.textBody,
      background: colors.colorBackground,
      '&::placeholder': {
        color: 'rgba(31, 41, 55, 0.5)',
      },
    },
    buttonSearch: {
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
    breadcrumbs: {
      flexGrow: 1,
      color: theme.palette.primary.main,
    },
    iconSearch: {
      color: '#fff',
    },
  }))
  const classes = useStyles()
  const history = useHistory()
  const [textSearch, setTextSearch] = useState<string>('')
  const matches = useMediaQuery('(max-width:660px)')

  const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (textSearch) {
      const formattedSearchText = textSearch.replaceAll('/', '')
      history.push(`/buscar/q=${formattedSearchText}`)
      setTextSearch('')
    }
  }
  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1320px"
        alignItems={matches ? 'initial' : 'center'}
        width="100%"
        minHeight="56px"
        height="100%"
        justifyContent={matches ? 'space-between' : 'flex-end'}
        padding={matches ? '20px' : '6px'}
        flexDirection={matches ? 'column' : 'row'}
        style={{
          gap: matches ? 20 : 0,
        }}
      >
        <Breadcrumbs separator="/" className={classes.breadcrumbs}>
          <Link
            href="/"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault()
              history.push('/')
            }}
          >
            <TbHome className={classes.icon} />
          </Link>
          <Link href={`/categoria/${service.categoria_slug}`}>
            <Typography
              style={{
                color: colors.accentColor,
                display: 'block',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '300px',
                fontSize: theme.typography.pxToRem(14),
                fontWeight: 400,
              }}
            >
              {service.categoria_nome}
            </Typography>
          </Link>
          <Typography
            style={{
              color: colors.accentColor,
              display: 'block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '300px',
              fontSize: theme.typography.pxToRem(14),
              fontWeight: 400,
            }}
          >
            {slug}
          </Typography>
        </Breadcrumbs>
        <form
          onSubmit={submitSearch}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexGrow: 1,
            minWidth: 240,
          }}
        >
          <input
            value={textSearch}
            onChange={(e): void => setTextSearch(e.target.value)}
            className={classes.inputSearch}
            style={{
              maxWidth: matches ? '' : 300,
              width: '100%',
              borderRadius: '4px 0px 0px 4px',
              border: `1px solid ${theme.palette.primary.main}`,
            }}
            placeholder="Buscar serviços"
          />
          <IconButton
            type="submit"
            className={classes.buttonSearch}
            style={{
              borderRadius: '0px 4px 4px 0px',
            }}
          >
            <TbSearch fontSize="default" className={classes.iconSearch} />
          </IconButton>
        </form>
      </Box>
    </Box>
  )
}
