import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  contentTypeScheduler: {
    margin: '32px 32px 35px 32px',
  },
  textInfoScheduler: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    fontFamily: 'Roboto',
    color: '#1F2937',
    textTransform: 'uppercase',
  },
  schedules: {
    margin: '24px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(640)]: {
      flexDirection: 'column',
      gap: 10,
    },
  },
  boxItem: {
    maxWidth: 256,
    cursor: 'pointer',
    width: '100%',
    height: 130,
    borderRadius: 4,
    border: '1px solid #D8D8E5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(640)]: {
      maxWidth: 'none',
    },
  },
  boxItemDisabled: {
    maxWidth: 256,
    cursor: 'pointer',
    width: '100%',
    height: 130,
    borderRadius: 4,
    border: '1px solid #D8D8E5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E7E9ED',
    color: '#9EA5B8',
    [theme.breakpoints.down(640)]: {
      maxWidth: 'none',
    },
  },
  iconItem: {
    width: 24,
    height: 24,
  },
  textItem: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    marginTop: 16,
  },
  warnIcon: {
    color: '#ffa300',
    fontSize: theme.typography.pxToRem(19),
  },
}))

export default useStyles
